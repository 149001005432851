<template>
  <li>
    <a :href="giftCard.data.url" class="giftcard">
      <img :src="giftCard.data.image_url"
        class="giftcard-image"
        role="presentation"
        alt=""
      />
    </a>
    <p class="giftcard-brand-collection">{{ brandCollection }}</p>
    <a :href="giftCard.data.url" class="giftcard-title">{{ giftCard.value }}</a>
    <p class="giftcard-price-range">{{ giftCard.data.price_range }}</p>
    <RatingStars
      :rating="giftCard.data.average_rating"
      :reviewCount="giftCard.data.review_count"
      :url="giftCard.data.url"
    />
  </li>
</template>

<script>
import RatingStars from "@/components/product-card/RatingStars.vue";

export default {
  name: "GiftCard",
  components: {
    RatingStars,
  },
  props: {
    giftCard: {
      type: Object,
      required: true
    }
  },
  computed: {
    brandCollection: function() {
      return decodeURIComponent(JSON.parse('"' + window.search.categoryTitle + '"'));
    }
  }
}
</script>

<style scoped>
.giftcard {
  display: flex;
  flex-direction: column;
}

.giftcard-image {
  margin-bottom: 24px;
}

.giftcard-brand-collection {
  color: #767676;
  font-size: var(--font-size-small);
  margin: 0 0 8px;
}

.giftcard-title {
  color: var(--color-black);
  display: block;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  margin: 0 0 16px;
}

.giftcard-title:hover,
.giftcard-title:focus,
.giftcard-title:active {
  text-decoration: none;
}

.giftcard-price-range {
  margin: 0 0 4px;
}
</style>
