<script setup>
import { getCurrentInstance } from 'vue'
const instance = getCurrentInstance();

const props = defineProps([
  'product',
  'selectedSwatches',
  'toggle_per_row_mobile'
]);

defineEmits([
  'setSwatchCookie',
  'swatchChange'
]);

const mediaQuery480 = '(max-width: 480px)';
const mediaQueryList480 = window.matchMedia(mediaQuery480);
const mediaQuery768 = '(max-width: 768px)';
const mediaQueryList768 = window.matchMedia(mediaQuery768);

mediaQueryList480.addEventListener('change', () => {
  instance.proxy.$forceUpdate();
});

mediaQueryList768.addEventListener('change', () => {
  instance.proxy.$forceUpdate();
});

function productSwatches(data) {
  if (data?.swatch_options?.[206] === undefined) {
    return [];
  }

  let swatches = Object.values(data.swatch_options[206]);

  // Filter out swatches with no active products
  swatches = swatches.filter((swatch) => {
    return swatch.child_ids.length;
  });

  // Sort colors alphabetically
  swatches = swatches.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  // Sort featured color to first pos
  swatches = swatches.sort((a, b) => { /* eslint-disable-line no-unused-vars */
    return parseInt(a.option_id) === parseInt(data.featured_color.value) ? -1 : 1;
  });

  return swatches.slice(0, 6);
}

function swatchBackground(swatch) {
  let style;
  if (swatch.type === '1') {
    style = 'background-color: ' + swatch.value + ';';

    if (swatch.additional_swatch1 && !swatch.additional_swatch2) {
      style += ' background-image: linear-gradient(0deg, ' +
        swatch.additional_swatch1 + ' 0%, ' +
        swatch.additional_swatch1 + ' 50%,' +
        swatch.value + ' 50%,' +
        swatch.value + ' 100%);'
    }

    if (swatch.additional_swatch1 && swatch.additional_swatch2) {
      style += ' background-image: linear-gradient(0deg, ' +
        swatch.additional_swatch2 + ' 0%, ' +
        swatch.additional_swatch2 + ' 25%,' +
        swatch.additional_swatch1 + ' 25%, ' +
        swatch.additional_swatch1 + ' 50%, ' +
        swatch.value + ' 50%,' +
        swatch.value + ' 100%);'
    }
  } else if (swatch.type === '2') {
    style = 'background: url(' + window.location.origin + '/media/attribute/swatch/swatch_image/30x20' + swatch.value + ') no-repeat center; background-size: 110%;';
  }

  return style;
}

function swatchCount(active) {
  let visibleSwatches = active;
  let modifier = 6;

  if (window.matchMedia(mediaQuery480).matches
  && props.toggle_per_row_mobile !== 1) {
    modifier = 4;
  } else if (window.matchMedia(mediaQuery768).matches
  && props.toggle_per_row_mobile !== 1) {
    modifier = 5;
  }

  return (visibleSwatches - modifier);
}
</script>

<template>
  <fieldset class="product-swatches" role="radiogroup">
    <legend class="swatch-caption">{{ product.value + ' swatches' }}</legend>

    <div class="swatch-container">
      <label
        :class="{
          active: props.selectedSwatches[product.data.id] === swatch.option_id,
          white: swatch.label === 'White',
          'multi-color': swatch.additional_swatch1
        }"
        :data-product-id="swatch.child_ids[0]"
        :for="['swatch', product.data.id, swatch.option_id].join('-')"
        :key="swatch.option_id"
        :style="swatchBackground(swatch)"
        class="swatch swatch-item"
        v-for="swatch in productSwatches(product.data)"
        >

        <input
          :aria-label="swatch.label"
          :id="['swatch', product.data.id, swatch.option_id].join('-')"
          :name="'swatch-' + product.data.id"
          :value="swatch.option_id"
          @input="$emit('swatchChange', product, swatch)"
          class="swatch-radio"
          type="radio"
        />
      </label>
    </div>

    <a
      :href="product.data.url"
      :title="product.value + ' has ' + swatchCount(product.data.active_swatch_count) + ' additional swatches'"
      @click="$emit('setSwatchCookie', product)"
      class="swatch-count"
      v-if="swatchCount(product.data.active_swatch_count) > 0"
    >+{{ swatchCount(product.data.active_swatch_count) }}</a>
  </fieldset>
</template>

<style scoped>
.product-swatches {
  /* Undo UA Styles */
  border: 0;
  margin: 0;
  padding: 0;

  /* Custom Styles */
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.swatch-caption,
.swatch-radio {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.swatch-container {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
  max-width: 320px;
  padding: 0;
  white-space: nowrap;
  width: calc(100% - 35px);
}

.swatch {
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 39px;
  min-width: 24px;
  width: calc((90% - 25px) / 6);
  position: relative;
}

.swatch-item {
  aspect-ratio: 1/1;
  background: var(--color-black);
  border-radius: 100%;
  border: 1px solid var(--color-white);
  cursor: pointer;

  @media (prefers-reduced-motion: no-preference) {
    transition: border 0.3s ease;
  }
}

.swatch-item.active,
.swatch-item:hover {
  border-color: var(--color-black);
}

.swatch-item.white:not(.active) {
  border-color: #dadada;
}

.swatch-item:has(.swatch-radio:focus-visible) {
  border-color: var(--color-white) !important;
  outline: var(--brand-primary) dashed 1px;
}

.swatch-item.white:has(.swatch-radio:focus-visible) {
  border-color: var(--color-gray40) !important;
}

.swatch-count {
  color: var(--brand-primary);
  text-align: right;
  text-decoration: underline;
  width: 25px;
}

/* Smallest Mobile Only */
@media only screen and (max-width: 390px) {
  .product-items:not(.per-row-1) .swatch {
    max-width: 24px;
  }
}

/* Smaller Mobile Only */
@media only screen and (max-width: 480px) {
  .product-items:not(.per-row-1) .swatch {
    width: calc((85% - 10px) / 4);
  }

  .product-items:not(.per-row-1) .swatch:nth-child(n+5) {
    display: none;
  }
}

/* Mobile Only */
@media only screen and (max-width: 768px) {
  .product-items:not(.per-row-1) .swatch {
    width: calc((85% - 10px) / 5);
  }

  .product-items:not(.per-row-1) .swatch:nth-child(n+6) {
    display: none;
  }

  .product-items:not(.per-row-1) .swatch-container {
    max-width: 250px;
  }
}

/* Desktop Only */
@media only screen and (min-width: 1024px) {
  .swatch {
    width: 24px;
  }
}
</style>
