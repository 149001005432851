<template>
  <div class="brand-bar">
    <button v-if="showLeft() === true" class="left" @click="leftButton">
      <ScrollArrowLeft />
    </button>
    <div class="brand-callout">
        <a class="brand-link" :href="brandLink(brand.target_url)">
            <img :src="brandImage(brand.media_url)"
                height="75"
                width="220"
                :alt="brand.frontend_label" />
            <p class="callout-text">
                <span class="desktop">{{ brand.frontend_label }}</span>
                <span class="mobile"><span class="text">{{ brand.mobile_frontend_label }}</span>
                  <img src="https://res.cloudinary.com/scrubsandbeyond/image/upload/search/swipe.gif"
                       width="50" height="26" class="swipe" alt=""></span>
            </p>
        </a>
    </div>

    <div class="collections-container">
      <ul class="collections-wrapper" ref="collectionContainer" @scroll="handleScroll($event)">
        <li v-for="(collection, index) in collections" class="collection" :class="{active: isActiveBrandPage(collection)}" :key="index">
          <a :href="collection.target_url">
            <img :src="collectionImage(collection.media_url)"
                height="55"
                width="180"
                alt="" />
            <p class="collection-copy">{{ collection.frontend_label }}</p>
          </a>
        </li>
      </ul>
      <button v-if="showRight() === true" class="right" @click="rightButton">
        <ScrollArrowRight />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ScrollArrowLeft from "@/components/icon/ScrollArrowLeft.vue";
import ScrollArrowRight from "@/components/icon/ScrollArrowRight.vue";

export default {
    name: "BrandBar",
    components: {
      ScrollArrowLeft,
      ScrollArrowRight
    },
    data() {
      return {
        collections: this.getCollections(),
        brand: this.getBrand(),
        scrollLeft: 0,
        minBrandsToShow: 2
      };
    },
    setup() {
      const collectionContainer = ref(null);

      return {
        collectionContainer
      };
    },
    methods: {
        leftButton () {
            // 275 is 125% of the width of one collection card
            this.scrollLeft -= 275;

            if (this.scrollLeft < 0) {
                this.scrollLeft = 0;
            }

            this.scroll();
        },
        rightButton() {
            // 275 is 125% of the width of one collection card
            this.scrollLeft += 275;

            if (this.scrollLeft > this.maxScrollLeft()) {
                this.scrollLeft = this.maxScrollLeft();
            }

            this.scroll();
        },
        scroll() {
            let content = this.$refs.collectionContainer;

            content.scrollTo({
                left: this.scrollLeft,
                behavior: 'smooth'
            });
        },
        showLeft() {
            return this.collections.length >= this.minBrandsToShow && this.scrollLeft > 0;
        },
        showRight() {
            // This method is broken up into 3 checks because collections.length exists always
            // but this.maxScrollLeft will flip out on first load because the DOM hasn't rendered.
            // We never show if there are less than 3, even first load
            if(window.matchMedia('(min-width: 1340px)').matches) {
                this.minBrandsToShow = 3
            }
            if (this.collections.length <= this.minBrandsToShow) {
                return false;
            }

            // Show on first load
            if (this.$refs.collectionContainer === undefined) {
                return true;
            }

            return this.scrollLeft < this.maxScrollLeft();
        },
        maxScrollLeft() {
            // Default value on first load
            if (this.$refs.collectionContainer === undefined) {
                return 0;
            }

            return this.$refs.collectionContainer.scrollWidth - this.$refs.collectionContainer.clientWidth;
        },
        handleScroll(event) {
            // @todo debounce?
            this.scrollLeft = event.target.scrollLeft;
        },
        getBrand() {
            return window.search.categoryBrand || [];
        },
        getCollections() {
            return window.search.categoryBrand?.brand_collection.sort((a, b) => {
                a = parseInt(a.position);
                b = parseInt(b.position);
                return a - b;
            }) || [];
        },
        brandImage(url) {
            url = url.replace('/upload/', '/upload/e_trim:10,dpr_2.0,c_fit,w_220,h_75/')

            return url;
        },
        collectionImage(url) {
            url = url.replace('/upload/', '/upload/c_fit,w_180,h_55,dpr_2.0/');

            return url;
        },
        isActiveBrandPage(collection) {
            let isActive = false;

            if (collection.target_url === (window.location.origin + window.location.pathname)) {
                isActive = true;
            }

            return isActive;
        },
        brandLink(target_url) {
            let url = new URL(target_url, window.location.origin);
            return url.toString();
        }
    }
}
</script>

<style scoped>
    .brand-bar {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 15px;
        overflow: scroll;
        position: relative;
    }

    .brand-callout {
        flex: 1 0 230px;
    }

    .brand-link {
        display: block;
    }

    .callout-text {
        margin: 1em 0 0;
    }

    .brand-callout .desktop {
        display: none;
    }
    .brand-callout .mobile {
        display: flex;
    }
    .brand-callout .mobile .text {
        display: block;
        width: calc(100% - 55px);
    }
    .brand-callout .swipe {
        margin-left: 5px;
    }
    @media (prefers-reduced-motion) {
        .brand-callout .mobile .text {
            width: auto;
        }

        .brand-callout .swipe {
            display: none;
        }
    }
    @media only screen and (min-width: 768px) {
        .brand-callout .desktop {
            display: block;
        }
        .brand-callout .mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 1023px) {
        .brand-bar {
            border-bottom: solid 1px var(--color-lighter-gray);
            clear: both;
            order: 3;
            padding: 0 20px 15px;
        }
    }

    @media only screen and (min-width: 1024px) {
        .brand-bar {
            float: right;
            margin-inline: 60px;
            overflow: visible;
            width: calc(78% - 120px);
        }
    }

    @media only screen and (min-width: 1340px) {
        .brand-bar {
            margin-right: 0;
            width: calc(78% - 60px);
        }
    }

    .left,
    .right {
        appearance: none;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        display: none;
        height: 44px;
        position: absolute;
        top: calc(50% - (44px / 2)); /* 44 is height/width of button, we want to offset by half to center */
        width: 44px;
    }

    @media only screen and (min-width: 1024px) {
        .left,
        .right {
            display: block;
        }
    }

    button.left {
        left: -52px; /* 44px hitbox, 60px gap. (60-44)/2 = 8. 44+8 = 52. */
    }

    button.right {
        right: -52px; /* 44px hitbox, 60px gap. (60-44)/2 = 8. 44+8 = 52. */
    }

    @media only screen and (min-width: 1024px) {
        .collections-container {
            overflow: hidden;
            flex: 1 1 100%;
        }
    }

    .collections-wrapper {
        display: flex;
    }

    @media only screen and (min-width: 1024px) {
        .collections-wrapper {
            overflow: scroll;
        }
    }

    .brand-bar::-webkit-scrollbar,
    .collections-wrapper::-webkit-scrollbar {
      display: none;
    }

    .collection {
        border: solid 1px var(--color-lighter-gray);
        border-radius: 5px;
        flex: 1 0 205px;
    }

    .collection:not(:last-child) {
        margin-right: 15px;
    }

    @media only screen and (min-width: 768px) {
        .collection {
            width: 100%;
            min-width: 200px;
        }
    }

    .collection a {
        display: block;
        padding: 12px;
    }

    .collection a:focus {
        outline: none;
    }

    .collection:hover,
    .collection:focus,
    .active {
        border-color: var(--brand-primary);
    }

    a {
        color: var(--brand-primary);
        text-decoration: none;
    }

    ul {
        list-style: none;
        margin-block: 0;
        padding-left: 0;
    }

    .collection-copy {
        line-height: 1.25;
        margin-bottom: 0;
        margin-top: 1em;
        font-size: calc(var(--font-size-small) - 1px);
    }
</style>
