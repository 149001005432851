<script setup>
import { useCssModule } from "vue";
import { itemsToShow } from "@/composables/facets/itemsToShow.js";
import { facetProps } from "@/composables/facets/facetProps.js";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";
import FacetTitle from "@/components/facets/partials/FacetTitle.vue";
import ShowMoreButton from "@/components/facets/partials/ShowMoreButton.vue";

const props = defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();

const optionModel = facetOptionModel(emit);
const showMore = defineModel({type: Boolean, default: false});
</script>

<template>
  <div>
    <FacetTitle
      :displayName="displayName"
      :name="props.name"
      :model="optionModel"
    />

    <ul :class="$styles.list">
      <li
        v-for="option in itemsToShow(options, data?.number_filter_options, data?.max_number_filter_options, showMore)"
        :key="option.value"
        :class="$styles['list-item']"
      >
        <label :class="[
          $styles['item-label'],
          $styles['gap-4'],
        ]">
          <input
            :value="name + ':' + option.value"
            :class="$styles.checkbox"
            v-model="optionModel"
            type="checkbox"
          />

          {{ option.display_name}}
        </label>
      </li>
    </ul>

    <ShowMoreButton
      v-if="data?.max_number_filter_options > data?.number_filter_options"
      v-model="showMore"
    />
  </div>
</template>

<style module src="@/css/facets.css" />
