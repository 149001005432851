<template>
  <component
    class="custom-content"
    :is="element"
    :data-blocks="content.data.blocks"
    :data-placement="content.data.placement"
    :data-position="content.data.position"
    :style="contentStyles"
    v-if="showCustomContent"
  >
  <component
    :is="hyperlink"
    :href="hyperlinkHref">
    <picture
      v-if="showImage"
      :class="{
        'has-mobile': showMobileImage,
        'has-desktop': showDesktopImage
      }"
    >
      <source
        v-if="showDesktopImage && showMobileImage"
        :srcset="content.data.assetUrl"
        media="(min-width: 768px)"
      />
      <img
        :src="showMobileImage ? content.data.mobileAssetUrl : content.data.assetUrl"
        :alt="altText"
        @load="changeAltText()"
      />
    </picture>
  </component>
    <div class="content"
     :data-textalignment="content.data.textalignment"
     v-if="showContent"
     >
      <h2 class="content-heading">{{ content.data.header }}</h2>
      <p class="content-body">{{ content.data.body }}</p>
      <a
        :href="content.data.ctaLink"
        class="btn"
        v-if="(content.data.ctaLink && content.data.ctaText)"
      >{{ content.data.ctaText }}</a>
    </div>
  </component>
</template>

<script>
export default {
  name: "Content",
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      defaultBgColor: '#FFFFFF',
      defaultTextColor: '#333333',
      defaultButtonColor: '#334C4C',
      defaultButtonHoverColor: '#5C7070',
      // The "Custom Content" is a hack for PowerMapper.  It does not recognize
      // the alt text that is getting loaded.  This is just a placeholder that
      // should get cleared as soon as the Constructor response is applied and
      // shouldn't ever get read to a user.
      altText: 'Custom Content',
    }
  },
  computed: {
    contentStyles: function() {
      let styles = {}

      styles['--bgcolor'] =  this.content.data.bgcolor ?? this.defaultBgColor;
      styles['--textcolor'] =  this.content.data.textcolor ?? this.defaultTextColor;
      styles['--buttoncolor'] =  this.content.data.textcolor ?? this.defaultButtonColor;
      styles['--buttonhovercolor'] =  this.content.data.textcolor ?? this.defaultButtonHoverColor;
      styles['--buttontextcolor'] = this.buttonTextColor(styles['--buttoncolor']);

      return styles;
    },
    element: function () {
      if (this.content.data.placement === 'top') {
        return 'div';
      }

      return 'li'
    },
    hyperlink: function() {
      if (this.content.data.ctaLink) {
        return 'a';
      }

      return 'span';
    },
    hyperlinkHref: function() {
      if (this.content.data.ctaLink) {
        return this.content.data.ctaLink;
      }

      // return null or undefined for conditional attr
      return null;
    },
    showCustomContent: function() {
      if (this.showContent) {
        return true;
      }

      if (this.isMobile() && this.showMobileImage) {
        return true;
      }

      return this.isDesktop() && this.showDesktopImage;
    },
    showDesktopImage: function() {
      return !!(this.content.data.assetUrl && this.content.data.altText);
    },
    showMobileImage: function() {
      return !!(this.content.data.mobileAssetUrl && this.content.data.mobileAssetAltText);
    },
    showImage: function() {
      return this.showMobileImage || this.showDesktopImage;
    },
    showContent: function () {
      return !!(this.content.data.header ||
          this.content.data.body ||
          (this.content.data.ctaLink && this.content.data.ctaText));
    }
  },
  methods: {
    buttonTextColor: function(bgColor) {
      // https://stackoverflow.com/a/41491220

      // If a 3 digit hexcode is passed it the conversion function fails
      // This if block expandes a 3 digit hex to a 6 digit hex
      if (bgColor.length < 7) {
        bgColor = '#' + bgColor[1] + bgColor[1] + bgColor[2] + bgColor[2] + bgColor[3] + bgColor[3];
      }
      let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor,
          darkColor = '#000',
          lightColor = '#fff',
          r = parseInt(color.substring(0, 2), 16), // hexToR
          g = parseInt(color.substring(2, 4), 16), // hexToG
          b = parseInt(color.substring(4, 6), 16), // hexToB
          uicolors = [r / 255, g / 255, b / 255],
          c = uicolors.map((col) => {
            if (col <= 0.03928) {
              return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
          }),
          L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])

      return (L > 0.179) ? darkColor : lightColor;
    },
    isDesktop() {
      return window.matchMedia('(min-width: 768px)').matches;
    },
    isMobile() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
    changeAltText() {
      let hasMobileAlt = this.content.data.mobileAssetAltText?.length > 0;
      let hasAlt = this.content.data.altText?.length > 0;

      let altText = '';
      if (this.isMobile() && hasMobileAlt) {
        altText = this.content.data.mobileAssetAltText;
      } else if (hasAlt) {
        altText = this.content.data.altText;
      }

      this.altText = altText;
    }
  },
}
</script>

<style scoped>
.custom-content {
  background-color: var(--bgcolor);
  color: var(--textcolor);
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .custom-content:nth-child(odd) {
    margin-left: -15px;  /* odd element works */
  }

  .custom-content:nth-child(even) {
    margin-right: -8px; /* changed to 8px to remove overflow */
  }

  .per-row-1  .custom-content {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 1024px) {
  [data-position="banner"] {
    width: calc(78% - 60px);
    float: right;
  }
}

.custom-content:has(.content) {
  justify-content: center;
}


@media (max-width: 767px) {
  picture.has-desktop:not(.has-mobile) {
    display: none;
  }
}

@media (min-width: 768px) {
  picture.has-mobile:not(.has-desktop) {
    display: none;
  }
}

img {
  height: auto;
  object-fit: cover;
  width: 100%;
}

[data-blocks="2"] {
  grid-column: span 2;
}

@media (min-width: 1024px) {
  [data-position="right"][data-blocks="2"] {
    grid-column: 3 / span 2;
  }

  .per-row-3 [data-position="right"][data-blocks="2"] {
    grid-column: 2 / span 2;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.content-heading {
  font-size: calc(var(--font-size-large) + 6px);
}

.content-body {
  font-size: var(--font-size-small);
}

.custom-content:not([data-placement="top"]) .content {
  padding: 15px;
}

[data-textalignment="left"] {
  align-items: flex-start;
}

[data-textalignment="center"] {
  align-items: center;
}

[data-textalignment="right"] {
  align-items: flex-end;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--buttoncolor);
  border-radius: 35px;
  color: var(--buttontextcolor);
  font-size: calc(var(--font-size-small) - 2px);
  letter-spacing: 1px;
  min-height: 32px;
  padding-block: 6px;
  padding-inline: 14px;
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    transition: background-color 0.3s ease;
  }
}

.btn:hover,
.btn:focus,
.btn:active {
  background-color: var(--buttonhovercolor);
}
</style>
