<template>
  <div class="pages" v-if="loaded">
    <ul class="items pages-items">
      <li v-for="page in pages()" :key="page.index" :class="{ 'item': page.url !== false, 'item current': page.url === false }">
        <a v-if="page.url !== false" @click.prevent.stop="changeUrl(page.url)" :class="{ 'page': page.url !== false, 'prev': page.text === 'Prev', 'next': page.text === 'Next' }" :href="page.url">
          <span>{{ page.text }}</span>
        </a>
        <strong v-if="page.url === false" class="page">
          <span class="label" style="display:none;">Current Page</span>
          <span>{{ page.text }}</span>
        </strong>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pager",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    loaded: {
      default: false,
      type: Boolean
    },
    numResults: {
      type: Number,
      required: true
    },
    pageVar: {
      type: String,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      frameLength: 5,
      jump: 5
    }
  },
  methods: {
    changeUrl(url) {
      // Update Url
      let page = Number(new URL(url).searchParams.get(this.pageVar)) || 1;

      this.$root.selectedFacets.setPage(page);
      this.$root.selectedFacets.updateUrl();

      // Scroll to top of page as it changes
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.$emit('pageChange');
    },
    getPageUrl(pageNumber) {
      let url = new URL(window.location);

      url.searchParams.set(this.pageVar, pageNumber);

      return url.toString();
    },
    pages() {
      let frameStart = 0,
          frameEnd = 0,
          half = Math.ceil(this.frameLength / 2),
          page = {},
          lastPageNumber = Math.ceil(this.numResults / this.perPage);

      if (lastPageNumber <= this.frameLength) {
        frameStart = 1;
        frameEnd = lastPageNumber;
      } else if (this.currentPage >= half && this.currentPage <= lastPageNumber - half) {
        frameStart = this.currentPage - half + 1;
        frameEnd = frameStart + this.frameLength - 1;
      } else if (this.currentPage < half) {
        frameStart = 1;
        frameEnd = this.frameLength;
      } else if (this.currentPage > lastPageNumber - half) {
        frameStart = lastPageNumber - this.frameLength + 1;
        frameEnd = lastPageNumber;
      }

      let pageCount = 1;

      // Can Show Prev Link
      if (frameStart - 1 > 1) {
        let previous = (this.currentPage - 1);
        page[pageCount++] = {
          text: 'Prev',
          url: this.getPageUrl(previous)
        }
      }

      // Can Show First Page
      if (frameStart > 1) {
        page[pageCount++] = {
          text: '1',
          url: this.getPageUrl(1)
        };
      }

      // Can Show Previous Jump
      if (frameStart - 1 > 1) {
        let previous = Math.max(2, frameStart - this.jump);
        page[pageCount++] = {
          text: '...',
          url: this.getPageUrl(previous)
        }
      }

      // Frame Pages
      for (let frame = frameStart; frame <= frameEnd; frame++) {
        if (frame === this.currentPage) {
          page[pageCount++] = {
            text: frame,
            url: false
          }
        } else {
          page[pageCount++] = {
            text: frame,
            url: this.getPageUrl(frame)
          }
        }
      }

      // Can Show Next Jump
      if (lastPageNumber - frameEnd > 1) {
        let next = Math.min(lastPageNumber - 1, frameEnd + this.jump);

        page[pageCount++] = {
          text: '...',
          url: this.getPageUrl(next)
        }
      }

      // Can Show Last
      if (frameEnd < lastPageNumber) {
        page[pageCount++] = {
          text: lastPageNumber,
          url: this.getPageUrl(lastPageNumber)
        }
      }

      // Can Show Next Link
      if (lastPageNumber - frameEnd > 1) {
        let next = (this.currentPage + 1);
        page[pageCount++] = {
          text: 'Next',
          url: this.getPageUrl(next)
        }
      }

      return page;
    }
  }
}
</script>

<style scoped>
.pages {
  clear: left;
  float: left;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .pages {
    order: 6;
  }
}
ul.items.pages-items {
  padding: 0;
  list-style: none;
  text-align: center;
}
ul.items.pages-items li {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
ul.items.pages-items li a {
  text-decoration: none;
  color: var(--color-black);
  font-size: calc(var(--font-size-small) - 2px);
  display: inline-flex;
  align-items: center;
}
ul.items.pages-items li.current {
  background: #e5e5e5;
}
.prev:before,.next:after {
  content: '';
  width: 10px;
  height: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 90%;
  background-size: contain;
}
.prev:before {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjE1NTMzIDVMNC44OTAxNiAxLjI2NTE2QzUuMDM2NjEgMS4xMTg3MiA1LjAzNjYxIDAuODgxMjgxIDQuODkwMTYgMC43MzQ4MzRDNC43NDM3MiAwLjU4ODM4NyA0LjUwNjI4IDAuNTg4Mzg3IDQuMzU5ODQgMC43MzQ4MzRMMC4zNTk4MzUgNC43MzQ4M0MwLjIxMzM4OSA0Ljg4MTI4IDAuMjEzMzg5IDUuMTE4NzIgMC4zNTk4MzUgNS4yNjUxNkw0LjM1OTgzIDkuMjY1MTdDNC41MDYyOCA5LjQxMTYxIDQuNzQzNzIgOS40MTE2MSA0Ljg5MDE2IDkuMjY1MTdDNS4wMzY2MSA5LjExODcyIDUuMDM2NjEgOC44ODEyOCA0Ljg5MDE2IDguNzM0ODRMMS4xNTUzMyA1WiIgZmlsbD0iIzk3OTc5NyIvPgo8L3N2Zz4K');
  margin-right: 8px;
}
.next:after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00LjA5NDY3IDVMMC4zNTk4MzUgMS4yNjUxNkMwLjIxMzM4OSAxLjExODcyIDAuMjEzMzg5IDAuODgxMjgxIDAuMzU5ODM1IDAuNzM0ODM0QzAuNTA2MjgyIDAuNTg4Mzg3IDAuNzQzNzE4IDAuNTg4Mzg3IDAuODkwMTY1IDAuNzM0ODM0TDQuODkwMTYgNC43MzQ4M0M1LjAzNjYxIDQuODgxMjggNS4wMzY2MSA1LjExODcyIDQuODkwMTYgNS4yNjUxNkwwLjg5MDE2NiA5LjI2NTE3QzAuNzQzNzE5IDkuNDExNjEgMC41MDYyODMgOS40MTE2MSAwLjM1OTgzNiA5LjI2NTE3QzAuMjEzMzg5IDkuMTE4NzIgMC4yMTMzODkgOC44ODEyOCAwLjM1OTgzNiA4LjczNDg0TDQuMDk0NjcgNVoiIGZpbGw9IiM5Nzk3OTciLz4KPC9zdmc+Cg==');
  margin-left: 5px;
}
.prev {
  margin-right: 100px;
  color: var(--color-blue);
  display: flex;
}
.next {
  margin-left: 100px;
  color: var(--color-blue);
  display: flex;
}
@media only screen and (max-width: 625px) {
  ul.items.pages-items li {
    width: 25px;
  }

  .pages .item {
    margin: 0 5px 0 0;
  }

  .prev {
    margin-right: 0;
  }

  .next {
    margin-left: 5px;
  }
}
</style>
