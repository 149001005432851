<script setup>
import { useCssModule } from "vue";
import { itemsToShow } from "@/composables/facets/itemsToShow.js";
import { facetProps } from "@/composables/facets/facetProps.js";
import FacetTitle from "@/components/facets/partials/FacetTitle.vue";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";
import ShowMoreButton from "@/components/facets/partials/ShowMoreButton.vue";

const props = defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();

const showMore = defineModel({type: Boolean, default: false});
const optionModel = facetOptionModel(emit);

function getSwatch(type, swatch) {
  let style = '';

  if (type === '1') {
    style = 'background-color: ' + swatch + ';';
  } else if (type === '2') {
    style = 'background: url(' + window.location.origin + '/media/attribute/swatch/swatch_image/30x20' + swatch + ') no-repeat center; background-size: 110%;';
  }

  return style;
}
</script>

<template>
  <div>
    <FacetTitle
      :displayName="displayName"
      :name="props.name"
      :model="optionModel"
    />

    <ul :class="$styles.list">
      <li
        v-for="option in itemsToShow(options, data?.number_filter_options, data?.max_number_filter_options, showMore)"
        :key="option.value"
        class="list-item"
      >
        <label :class="[
          $styles['item-label'],
          $styles['gap-2'],
        ]">
          <input
            :style="getSwatch(option.data.swatch_type, option.data.swatch_value)"
            :value="name + ':' + option.value"
            v-model="optionModel"
            type="checkbox"
          />

          {{ option.display_name}}
        </label>
      </li>
    </ul>

    <ShowMoreButton
      v-if="data?.max_number_filter_options > data?.number_filter_options"
      v-model="showMore"
    />
  </div>
</template>

<style module src="@/css/facets.css" />

<style>
:root {
 --border-color-gray: #C4C4C4;
}
</style>

<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5em;

  @media (min-width: 1024px) {
    row-gap: 15px;
  }
}

.list-item {
  /* Magento Core Styles
    https://github.com/magento/magento2/blob/b57e30c9ae27e513da830ad1d3b20c6a94afa0e7/lib/web/css/source/lib/_typography.less#L384
  */
  margin-bottom: 0;
}

label {
  overflow-wrap: anywhere;
}

input[type="checkbox"] {
  /* UA Styles */
  appearance: none;
  margin: 0;

  /* Magento Core Styles
     https://github.com/magento/magento2/blob/b57e30c9ae27e513da830ad1d3b20c6a94afa0e7/lib/web/css/source/lib/_forms.less#L301
  */
  position: static;
  top: unset;

  /* Custom styles */
  border-radius: 100%;
  height: 35px;
  width: 35px;
  flex-shrink: 0;

  &:checked {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.74.388a1.323 1.323 0 1 1 1.872 1.871l-7.94 7.942a1.323 1.323 0 0 1-1.873 0L.388 5.789a1.324 1.324 0 0 1 1.871-1.872l3.476 3.476L12.741.388Z' fill='%23fff'/%3E%3C/svg%3E");
    border: solid 1px var(--color-black);
  }

  &:hover {
    cursor: pointer;
    border: solid 1px var(--color-black);
  }

  &:focus-visible {
    outline-color: var(--brand-primary);
  }
}

input[type="checkbox"][value*="White"] {
  border: solid 1px var(--border-color-gray);

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.74.388a1.323 1.323 0 1 1 1.872 1.871l-7.94 7.942a1.323 1.323 0 0 1-1.873 0L.388 5.789a1.324 1.324 0 0 1 1.871-1.872l3.476 3.476L12.741.388Z' fill='%23000'/%3E%3C/svg%3E");
  }
}

.swatch {
  width: 20px;
  height: 20px;
  display: inline-block;
}
</style>
