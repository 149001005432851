<template>
  <div>
    <div v-if="!loaded" :class="{ loaded: loaded }" class="skeleton text loading-item"></div>
    <div :class="{ loaded: loaded }" class="breadcrumbs-wrap">
        <a href="/" class="mobile back" aria-label="Home"></a>
        <a href="/">Home</a>
        <span v-for="crumb in crumbs" :key="crumb.display_name">
          <span class="separator">/</span>
          <a v-if="crumb.href" :href="crumb.href">{{ crumb.display_name }}</a>
          <span v-else>{{ crumb.display_name }}</span>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    crumbs: {
      type: Array,
      required: true
    },
    loaded: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.page-wrapper div.breadcrumbs-wrap-wrap {
  margin: 30px 0 15px;
  padding: 20px 0;
  order: -2;
}
.mobile.back,
.breadcrumbs-wrap:not(.loaded) {
  display: none;
}
.breadcrumbs-wrap {
  color: var(--color-dark-gray);
  margin-top: 5px;
}
.breadcrumbs-wrap a {
  color: var(--color-dark-gray);
  text-decoration: none;
}
.breadcrumbs-wrap .separator {
  padding: 0 5px;
}
.breadcrumbs-wrap span:last-child *:not(.separator) {
  font-weight: var(--font-weight-bold);
}
.skeleton.text {
  height: 18px;
  width: 200px;
}
/* Mobile Only */
@media only screen and (max-width: 1023px) {
  #search_browse div.breadcrumbs-wrap {
    /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.3); */
    /* margin: 0 auto 20px; */
    padding: 0 24px 13px;
    width: 100%;
    order: 1;
  }
  .breadcrumbs-wrap > *:not(:last-child),
  .breadcrumbs-wrap .separator {
    display: none;
  }
  .breadcrumbs-wrap span {
    font-size: var(--font-size-medium);
  }
  .mobile.back {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 10px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjg1NzQ2IDYuNzk2NDFMNi42MDUxMyAxMS41NDQxQzYuODEzNTkgMTEuNzUyNSA2LjgxMzU5IDEyLjA5MDUgNi42MDUxMyAxMi4yOTlDNi4zOTY2NyAxMi41MDc0IDYuMDU4NjggMTIuNTA3NCA1Ljg1MDIyIDEyLjI5OUwwLjE1NjM1NCA2LjYwNTEyQy0wLjA1MjEwNzggNi4zOTY2NiAtMC4wNTIxMDc4IDYuMDU4NjcgMC4xNTYzNTQgNS44NTAyMUw1Ljg1MDIyIDAuMTU2MzQzQzYuMDU4NjggLTAuMDUyMTE5MyA2LjM5NjY3IC0wLjA1MjExOTMgNi42MDUxMyAwLjE1NjM0M0M2LjgxMzU5IDAuMzY0ODA1IDYuODEzNTkgMC43MDI3ODggNi42MDUxMyAwLjkxMTI1TDEuNzE2NzggNS43OTk2TDE2LjA3MjcgNS40NDI4MUMxNi4zNDg4IDUuNDM1OTUgMTYuNTc4MSA1LjY1NDE4IDE2LjU4NSA1LjkzMDIzQzE2LjU5MTggNi4yMDYyOSAxNi4zNzM2IDYuNDM1NjQgMTYuMDk3NSA2LjQ0MjVMMS44NTc0NiA2Ljc5NjQxWiIgZmlsbD0iIzQ0NDQ0NCIvPgo8L3N2Zz4K');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
