export function itemsToShow(options, number_filter_options = 0, max_number_filter_options = 100, showMore = false) {
  let end = options.length;

  if (number_filter_options) {
    end = number_filter_options;
  }

  if (showMore) {
    end = max_number_filter_options;
  }

  return options.slice(0, end);
}
