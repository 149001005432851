<script setup>
import { useCssModule } from "vue";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";
import { facetProps } from "@/composables/facets/facetProps.js";
import FacetTitle from "@/components/facets/partials/FacetTitle.vue";

const props = defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();
const $ratingStars = useCssModule('rating-stars');

const optionModel = facetOptionModel(emit);
</script>

<template>
  <div>
    <FacetTitle
      :displayName="displayName"
      :name="props.name"
      :model="optionModel"
    />

    <ul :class="$styles.list">
      <li
        v-for="option in options"
        :key="option.value"
        :class="$styles['list-item']"
      >
        <label :class="$styles['item-label']">
          <input
            :value="name + ':' + option.value"
            :class="$styles.checkbox"
            v-model="optionModel"
            type="checkbox"
          />

          <span :class="[
            'star-rating',
            $ratingStars['star-rating']
          ]">
            <span :class="[
              'fill-ratings',
              $ratingStars['fill-ratings']
          ]">
              <span class="stars">★ ★ ★ ★ ★</span>
            </span>
            <span :class="$ratingStars['empty-ratings']">
              <span class="stars">★ ★ ★ ★ ★</span>
            </span>
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>

<style module src="@/css/facets.css" />
<style module="rating-stars" src="@/css/rating-stars.css" />

<style scoped>
.star-rating {
  margin-left: 14px;
}

.stars {
  display: block;
  letter-spacing: -1.25px;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

input[value*="4 Stars"] + .star-rating .fill-ratings .stars {
  width: 80%;
}

input[value*="3 Stars"] + .star-rating .fill-ratings .stars {
  width: 60%;
}

input[value*="2 Stars"] + .star-rating .fill-ratings .stars {
  width: 40%;
}

input[value*="1 Star"] + .star-rating .fill-ratings .stars {
  width: 20%;
}

label:has(input[value*="& Up"]):after {
  color: var(--color-dark-gray);
  content: "& Up";
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin-left: 10px;
  white-space: nowrap;
}
</style>
