<template>
  <Search ref="search" />
</template>

<script>
import Search from './components/Search.vue';
import selectedFacets from './store/selectedFacets';
import './global.css';

export default {
  name: 'App',
  components: {
    Search
  },
  created() {
    this.buildStateFromURL();

    this.bindOnPopStateHandler();

    // Search Bar Push/Pop State
    (function (history, self) {
      let pushState = history.pushState;
      history.pushState = function (state) {
        pushState.apply(history, arguments);

        if (!('updateUrl' in state) || state.updateUrl !== true) {
          self.$root.selectedFacets.clearFilters();

          self.buildStateFromURL();

          self.$refs.search.call();
        }
      };
    })(window.history, this);
  },
  data() {
    return {
      selectedFacets
    }
  },
  methods: {
    setFiltersFromURL() {
      let searchParams = new URLSearchParams(window.location.search);

      searchParams.forEach(function (value, key) {
        if (key.startsWith('f.')) {
          key = key.replace('f.', '');
          this.selectedFacets.setFilter(key + ':' + value);
        }
      }, this);
    },
    setPageFromURL() {
      let searchParams = new URLSearchParams(window.location.search);
      let page = searchParams.get('p') || 1;
      this.selectedFacets.setPage(page);
    },
    setSearchTermFromURL() {
      let searchParams = new URLSearchParams(window.location.search);
      let q = searchParams.get('q') || '';

      this.selectedFacets.setSearchTerm(q);
    },
    setSortOptionsFromURL() {
      let searchParams = new URLSearchParams(window.location.search);
      let sortOptions = {};

      searchParams.forEach(function(value, key) {
        if (key === 'sortBy') {
          sortOptions.sortBy = value;
        }

        if (key === 'sortOrder') {
          sortOptions.sortOrder = value;
        }
      }, this);

      this.selectedFacets.setSortOptions(sortOptions);
    },
    buildStateFromURL() {
      this.setFiltersFromURL();
      this.setPageFromURL();
      this.setSearchTermFromURL();
      this.setSortOptionsFromURL();
    },
    bindOnPopStateHandler() {
      window.onpopstate = () => {
        this.$root.selectedFacets.clearFilters();

        this.buildStateFromURL();

        this.$refs.search.call();
      };
    }
  }
}
</script>

<style>
body {
  margin: 0;
}

body:has(dialog[open]) {
  overflow: hidden;
}

#search_browse {
  font-family: var(--font-family-base);
  font-size: var(--font-size-small);
}

@media only screen and (max-width: 1023px) {
  #search_browse {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) {
  #search_browse {
    padding-top: 15px;
  }
}

.page-products.page-layout-2columns-left.catalogsearch-result-index .column.main {
  padding-inline: 2%;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  #search_browse * {
    animation: none !important;
    transition: none !important;
  }
}
</style>
