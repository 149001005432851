<script setup>
import selectedFacets from '@/store/selectedFacets';

const props = defineProps({
  sort_options: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(['facetChange']);

const optionModel = defineModel({
  get() {
    return props.sort_options.find((option) => option.status === 'selected')?.sort_by;
  },
  set (value) {
    let selected = props.sort_options.find((option) => option.sort_by === value);

    selectedFacets.setSortOptions({
      'sortOrder': selected.sort_order,
      'sortBy': selected.sort_by,
    });

    selectedFacets.updateUrl();

    emit('facetChange');
  }
});

</script>

<template>
  <div class="mobile-sort-by">
    <div class="filter-options-title">Sort by</div>
    <ul>
      <li v-for="option in sort_options">
        <label>
          <input
            type="radio"
            name="sort_by"
            :value="option.sort_by"
            v-model="optionModel"
          />

          {{ option.display_name }}
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.mobile-sort-by {
  border-bottom: 1px solid var(--border-color-gray);
  margin-bottom: 15px;
  padding-bottom: 15px;

  @media (max-width: 1023px) {
    margin: 15px 30px;
  }
}

ul {
  /* UA Styles */
  list-style: none;
  margin: 0;
  padding: 0;
}

li:not(:last-child) {
  margin-bottom: .75em;
}

label {
  display: flex;
  align-items: center;
  gap: 14px;

  &:hover {
    cursor: pointer;
  }
}

input[type="radio"] {
  accent-color: var(--brand-primary);
  height: 22px;
  margin: 0;
  width: 22px;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-color: var(--brand-primary);
  }
}
</style>
