<template>
  <div class="rating-stars" v-if="rating > 0">
    <a :href="url">
      <div :class="$ratingStars['star-rating']">
        <div :class="$ratingStars['fill-ratings']"
          :style="{ width: rating / 5 * 100 + '%' }"
        >
          <span class="stars">★ ★ ★ ★ ★</span>
        </div>
        <div :class="$ratingStars['empty-ratings']">
          <span class="stars">★ ★ ★ ★ ★</span>
        </div>
      </div>
      <span v-if="reviewCount" class="review-count">({{ reviewCount }})</span>
    </a>
  </div>
</template>

<script setup>
import { useCssModule } from "vue";
const $ratingStars = useCssModule('rating-stars');

defineProps({
  rating: {
    type: Number,
    required: false
  },
  reviewCount: {
    type: Number,
    required: false
  },
  url: {
    type: String,
    required: true
  },
});
</script>

<style module="rating-stars" src="@/css/rating-stars.css" />

<style scoped>
.rating-stars a {
  align-items: center;
  color: var(--brand-primary);
  display: flex;
  font-size: var(--font-size-small);
  gap: 5px;
  text-decoration: none;
}

.stars {
  display: inline-block;
  letter-spacing: -1.25px;
  width: max-content;
}
</style>
