<template>
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.35068 0.90625C6.10814 0.90625 4.86559 1.25278 3.85315 1.89963C2.26545 2.96231 1.29903 4.69495 1.06893 6.49689C0.838828 8.29884 1.32204 10.1008 2.17341 11.741C3.87616 14.9753 6.95951 17.401 9.58266 20.0577C10.8712 21.3514 12.0678 22.7375 13.1492 24.1698C14.2537 22.7144 15.4272 21.3514 16.7158 20.0577C19.362 17.401 22.4223 14.9753 24.125 11.741C24.9764 10.1239 25.4596 8.29884 25.2295 6.49689C24.9994 4.69495 24.056 2.96231 22.4453 1.92273C20.8346 0.860046 18.6256 0.629028 16.7618 1.29898C14.921 1.96893 13.4944 3.53986 13.1032 5.3418C12.712 3.53986 11.2854 1.96893 9.4446 1.29898C8.84634 1.02176 8.08701 0.90625 7.35068 0.90625Z"
          stroke="#797979"
          stroke-linecap="square"
          stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Heart"
}
</script>
