export const facetProps = {
  name: {
    type: String,
    required: true
  },
  displayName: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
}
