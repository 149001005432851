import { reactive } from 'vue'

const store = {
    debug: import.meta.env.MODE !== 'production',

    state: reactive({
        dirty: false,
        filters: {},
        page: 1,
        searchTerm: '',
        sort_options: {
            sortBy: 'relevance',
            sortOrder: 'ascending'
        },
    }),

    /**
     * Clear All Filters
     */
    clearFilters() {
        if (this.debug) {
            console.log('clearFilters called');
        }

        this.state.filters = {};
        this.state.dirty = true;
    },

    /**
     * Get Filters
     *
     * @returns Object
     */
    getFilters() {
        if (this.debug) {
            console.log('getFilters called');
        }

        return this.state.filters;
    },

    /**
     * Remove Filter by Value, Value is the same as the key
     *
     * @param {String} value
     */
    removeFilter(value) {
        if (this.debug) {
            console.log('removeFilter called with value:', value);
        }

        delete this.state.filters[value];
        this.state.dirty = true;
    },

    /**
     * Set Filter in State
     *
     * @param {String} value
     */
    setFilter(value) {
        if (this.debug) {
            console.log('setFilter called with value:', value);
        }

        if (!(value in this.state.filters)) {
            this.state.filters[value] = value;
            this.state.dirty = true;
            this.state.page = 1;
        }
    },

    getPage() {
        return this.state.page;
    },

    setPage(page) {
        this.state.page = Number(page);
        this.state.dirty = true;
    },

    getSearchTerm() {
        return this.state.searchTerm;
    },

    setSearchTerm(q) {
        this.state.searchTerm = q;
        this.state.dirty = true;
    },

    getSortOptions() {
        return this.state.sort_options;
    },

    setSortOptions(selectedOption) {
        if (selectedOption?.sortOrder !== 'ascending' && selectedOption?.sortOrder !== 'descending') {
            selectedOption.sortOrder = this.state.sort_options.sortOrder;
        }

        this.state.sort_options = selectedOption;
    },

    /**
     * Update Url from the filters
     */
    updateUrl() {
        let url = new URL(window.location);

        // Remove all filters from search params
        let deletes = [];
        url.searchParams.forEach(function (value, key) {
            if (key.startsWith('f.') || key.startsWith('p')) {
                deletes.push(key);
            }
        });
        deletes.forEach(param => url.searchParams.delete(param));

        // Sort Selected Filters and Append all filters to search params
        // eslint-disable-next-line no-unused-vars
        Object.entries(this.state.filters).sort().forEach(function ([key, facet]) {
            let param = facet.split(':');
            url.searchParams.append('f.' + param[0], param[1]);
        });

        Object.entries(this.state.sort_options).forEach(function([key, value]) {
            let paramInUrl = url.searchParams.get(key);

            if (paramInUrl) {
                url.searchParams.set(key, value);
            } else {
                url.searchParams.append(key, value);
            }
        });

        if (this.state.page !== 1) {
            url.searchParams.set('p', this.state.page);
        } else {
            url.searchParams.delete('p');
        }

        // Update Url
        let historyState = window.history.state;

        // searchParamChange comes from the scrubs/autocomplete module
        if (this.state.dirty && (historyState === null || !('searchParamChange' in historyState) || historyState.searchParamChange !== true)) {
            window.history.pushState({updateUrl: true}, undefined, url.toString());
            this.state.dirty = false;
            return;
        }

        window.history.replaceState({}, undefined, url.toString());
    }
}

export default store;
