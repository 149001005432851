<template>
    <div
      :class="{ loaded: loaded }"
      class="ribbon-banner skeleton loading-item"
      :style="getCategoryBannerColor() ? {
        'background-color': getCategoryBannerColor(),
        'color': getCategoryBannerTextColor()
      } : ''"
    >
        <p>{{ getCategoryBannerText() }}</p>
    </div>
</template>

<script>
export default {
  name: "RibbonBanner",
  props: {
    loaded: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    getCategoryBannerColor() {
      return window?.search?.categoryBanner.color || false;
    },
    getCategoryBannerText() {
      return window?.search?.categoryBanner.text || false;
    },
    getCategoryBannerTextColor() {
      let hex = window.search.categoryBanner.color.substring(1);
      // https://blog.karenying.com/posts/boost-visual-accessibility-by-auto-flipping-text-color
      return (this.contrastRatioPair(hex, 'FFFFFF') > this.contrastRatioPair(hex, '000000') ? '#FFFFFF' : '#000000');
    },
    hexToDecimal(hex_string) {
      return parseInt(hex_string, 16);
    },
    hexToRGB(hex) {
      const r = this.hexToDecimal(hex.substring(0, 2));
      const g = this.hexToDecimal(hex.substring(2, 4));
      const b = this.hexToDecimal(hex.substring(4, 6));

      return { r, g, b };
    },
    getLuminance(hex) {
      const rgb = this.hexToRGB(hex);

      for (const key in rgb) {
        let c = rgb[key];
        c /= 255;

        c = c > 0.03928 ? Math.pow((c + 0.055) / 1.055, 2.4) : (c /= 12.92);

        rgb[key] = c;
      }

      return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
    },
    contrastRatioPair(hex1, hex2) {
      const lum1 = this.getLuminance(hex1);
      const lum2 = this.getLuminance(hex2);

      return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
    }
  }
}
</script>

<style scoped>
.ribbon-banner {
    background: var(--brand-primary);
    color: var(--color-white);
    text-align: center;
    margin-left: 60px;
    height: 45px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    width: calc(78% - 60px);
    float: right;
    font-size: calc(var(--font-size-large) + 2px);
}
.ribbon-banner p {
    display: none;
    margin: 0;
    padding: 0;
}
.ribbon-banner.loaded {
    background: var(--brand-primary);
    animation: none;
    display: flex;
}
.ribbon-banner.loaded p {
    display: block;
}
/* Mobile Only */
@media only screen and (max-width: 1023px) {
    .ribbon-banner {
        height: auto;
        margin: 0 0 15px;
        order: 2;
        padding: 10px;
        width: 100vw;
    }
    .ribbon-banner p {
        max-width: 280px;
    }
}
</style>
