<template>
  <span />
</template>

<script>
export default {
  name: "GoogleAnalytics",
  props: {
    pageVar: {
      type: String,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  methods: {
    currentPage() {
      // Get Current Page From Url page variable
      return Number(new URLSearchParams(window.location.search).get(this.pageVar)) || 1;
    },
    getCategory() {
      return window.search?.filter ?? '';
    },
    getDataLayer() {
      return window.dataLayer || [];
    },
    getListType() {
      return window.search?.listType || '';
    },
    getProductPosition(position = 0) {
      let currentPage = this.currentPage();
      return currentPage > 1 ? (currentPage - 1) * this.perPage + ++position : ++position
    },
    productClick(product, position) {
      let dataLayer = this.getDataLayer(),
          productImpression = {
            'event': 'productClick',
            'ecommerce': {
              'click': {
                'actionField': {
                  'list': this.getListType()
                },
                'products': [{
                  'id': product.data?.sku || '',
                  'name': product.value || '',
                  'category': this.getCategory(),
                  'list': this.getListType(),
                  'position': this.getProductPosition(position)
                }]
              }
            }
          };

      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push(productImpression);
    }
  },
  updated: function() {
    let dataLayer = this.getDataLayer(),
        impressions = {
          'event': 'productImpression',
          'ecommerce': {
            'impressions': []
          }
        },
        position = this.getProductPosition(),
        self = this;

    this.products.forEach(function (product) {
      this.ecommerce.impressions.push({
        'id': product.data?.sku || '',
        'name': product.value || '',
        'category': self.getCategory(),
        'list': self.getListType(),
        'position': position++
      });
    }, impressions);

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(impressions);
  }
}
</script>

