<script setup>
import { ref, computed, watch } from 'vue';
import Close from "@/components/icon/Close.vue";
import ColorSwatch from "@/components/facets/ColorSwatch.vue";
import MobileSortBy from "@/components/facets/partials/MobileSortBy.vue";
import RatingStars from "@/components/facets/RatingStars.vue";
import Text from "@/components/facets/Text.vue";
import TextBubble from "@/components/facets/TextBubble.vue";
import TextWithSearch from "@/components/facets/TextWithSearch.vue";
import Toggle from "@/components/facets/Toggle.vue";
import selectedFacets from '@/store/selectedFacets';

defineProps({
  facets: {
    type: Array,
    required: true
  },
  loaded: {
    default: false,
    type: Boolean
  },
  sort_options: {
    type: Array,
    required: true
  },
  visible: {
    type: Boolean,
    required: true
  }
});
const emit = defineEmits([
  'facetChange',
  'hideFacets'
]);

const resizeObserver = new ResizeObserver((entries) => {
  containerSize.value = entries[0].contentBoxSize[0].inlineSize;
});
resizeObserver.observe(document.querySelector('#maincontent'));
const containerSize = ref(0);
const isDesktop = computed(() => {
  return containerSize.value >= 1024;
});
watch(isDesktop, function() {
  if (isDesktop.value) {
    emit('hideFacets');
  }
});

const selectedFacetCount = computed(() => {
  return Object.keys(selectedFacets.getFilters()).length;
})

function getComponent(facet) {
  let component;

  component = getComponentByName(facet.name);

  if (!component) {
    component = getComponentByFilterType(facet.data.filter_type)
  }

  return component;
}

// Hacky fix until I can get a new Component type added for Toggles
// on_sale and clearance should be the Toggle type.
// average_rating here until I can make an API call to change it to RatingStars
// None of these can be selected in Product Attributes
function getComponentByName(name) {
  let components = {
    'on_sale': Toggle,
    'on_clearance': Toggle,
    'average_rating': RatingStars,
  };

  return components[name] || null;
}

function getComponentByFilterType(filter_type) {
  if (!filter_type) {
    filter_type = 'text';
  }

  let components = {
    'text': Text,
    'text_with_search': TextWithSearch,
    'color_swatch': ColorSwatch,
    'text_swatch': Text,
    'text_bubble': TextBubble,
    'rating_stars': RatingStars,
  };

  return components[filter_type];
}

function clearFilters() {
  selectedFacets.clearFilters();
  selectedFacets.updateUrl();
  emit('facetChange');
}
</script>

<template>
  <component :is="isDesktop ? 'div' : 'dialog'"
    class="sidebar"
  >
    <div class="header" v-show="!isDesktop">
      <span class="title">Filter &amp; Sort</span>
      <button
        type="button"
        class="close"
        @click="$emit('hideFacets')"
      >
        <Close />
      </button>
    </div>

    <MobileSortBy
      v-show="!isDesktop"
      :sort_options="sort_options"
      @facetChange="$emit('facetChange')"
    />

    <div class="facets">
      <component
        v-for="facet in facets"
        :key="facet.name"
        :class="[
          'facet',
          facet.name
        ]"
        :is="getComponent(facet)"
        :data="facet.data"
        :name="facet.name"
        :displayName="facet.display_name"
        :options="facet.options"
        @facetChange="$emit('facetChange')"
      ></component>
    </div>

    <div class="mobile-action-buttons" v-show="!isDesktop">
      <button
        type="button"
        class="button secondary"
        @click="clearFilters()">
        Clear <span v-if="selectedFacetCount">({{ selectedFacetCount }})</span>
      </button>

      <button
        type="button"
        class="button primary"
        @click="$emit('hideFacets')"
      >
        Apply
      </button>
    </div>

  </component>
</template>

<style scoped>
.sidebar {
  line-height: 1.5;

  @media (min-width: 1024px) {
    float: left;
    width: 20%;
  }
}

@keyframes slide-in-right {
  from { transform: translateX(-100%) }
}

@keyframes slide-out-right {
  to { transform: translateX(-100%) }
}

dialog.sidebar {
  /* Undo UA Styles */
  border: 0;
  height: 100vh;
  margin: 0;
  max-height: 100vh;
  max-width: 100vw;
  padding: 0;
  width: 100vw;

  /* Custom Style */
  &[open] {
    animation: slide-in-right .5s cubic-bezier(.25, 0, .3, 1);
  }

  &[open]::backdrop {
    display: none;
  }

  &.is-hiding {
    animation: slide-out-right .5s cubic-bezier(.25, 0, .3, 1);
  }
}

.header {
  align-items: center;
  background: var(--color-white);
  border-bottom: 1px solid var(--border-color-gray);
  display: flex;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  padding: 7px 14px 7px 30px;
  position: sticky;
  top: 0;
  z-index: 2;

  & .close {
    all: unset;
    line-height: 1;
    text-align: center;
    width: 44px;
    height: 44px;

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      outline: revert;
    }

    & :deep(svg) {
      height: 15px;
      width: 15px;
    }
  }
}

.sidebar :deep(.filter-options-title) {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.5em;
}

.facet {
  @media (max-width: 1023px) {
    margin: 15px 30px;
  }
}

.facet:not(:first-child) {
  margin-top: 25px;
}


/* :not(.price) is a hacky fix so that price, on_sale, and on_clearance
    all appear together.  There has to be a better way to do it but I can't
    figure it out. */
.facet:not(:last-child):not(:has(.toggle)):not(.price) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.mobile-action-buttons {
  align-items: center;
  background: var(--color-white);
  border-top: 0.5px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: sticky;
  right: 0;
  width: 100%;
  z-index: 1;
}

.button {
  background: var(--color-black);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  color: var(--color-white);
  height: 40px;
  letter-spacing: .88px;
  margin-inline: 7px;
  max-width: 150px;
  padding-inline: 30px;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.primary {
  &:focus-visible {
    background: var(--brand-primary);
  }
}

.secondary {
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
}
</style>
