<script setup>
import { computed } from "vue";

const props = defineProps({
  displayName: {
    type: String,
    required: true,
  },
  model: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true
  }
});

const count = computed(() => {
  return props.model.filter(
    (opt) => opt.split(':')[0] === props.name
  ).length;
});
</script>

<template>
  <div class="facet-title">
    {{ displayName }}

    <span class="count" v-if="count">
      (<span class="count-number">{{ count }}</span>)
    </span>
  </div>
</template>

<style scoped>
.facet-title {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: 1em;
}

.count-number {
  padding: 0 1px;
}
</style>
