<template>
  <button class="wishlist"
    :aria-pressed="inWishlist"
    :aria-label="label"
    :title="label"
    @click="toggleWishlist()"
    type="button"
  >
    <Heart />
  </button>
</template>

<script>
import Heart from "@/components/icon/Heart.vue";

export default {
  name: "WishlistIcon",
  components: {
    Heart
  },
  emits: [
    'addToWishlist',
    'removeFromWishlist',
  ],
  props: [
    'inWishlist',
    'productName'
  ],
  computed: {
    label: function() {
      if (this.inWishlist) {
        return 'Remove ' + this.productName + ' from Wishlist';
      } else {
        return 'Add ' + this.productName + ' to Wishlist';
      }
    }
  },
  methods: {
    toggleWishlist: function() {
      if (this.inWishlist) {
        this.$emit('removeFromWishlist');
      } else {
        this.$emit('addToWishlist');
      }
    },
  }
}
</script>

<style scoped>
.wishlist {
  all: unset;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  border: solid 1px transparent;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: background 0.3s ease;
  width: 44px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .product-items.per-row-1 .product-item .wishlist,
  .product-items:not(.per-row-1) .product-item:nth-child(even) .wishlist {
    right: -7px; /* Images are -15px, this should get an 8px gap */
  }
}

.wishlist:hover {
  background: rgba(255, 255, 255, 0.8);
}

.wishlist:focus-visible {
  border: solid 1px var(--brand-primary);
}

.wishlist :deep(svg path) {
  stroke: var(--color-gray);
}

.wishlist :deep(svg),
.wishlist :deep(svg path) {
  transition: all 0.3s ease;
}

.product.in-wishlist .wishlist :deep(svg path),
.wishlist.active :deep(svg path) {
  fill: var(--brand-primary);
  stroke: var(--brand-primary);
}

@media (hover: hover) {
  .wishlist:hover :deep(svg path) {
    fill: var(--brand-primary);
    stroke: var(--brand-primary);
  }
}
</style>
