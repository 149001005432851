<template>
    <div class="selected-filters" :class="{ loaded: loaded }">
        <div v-if="!loaded && selected_facet" class="skeleton pill"></div>

        <div v-for="value in getFilterDisplayName(facets,  $root.selectedFacets.getFilters())" :key="value.id" :data-test="value.id" :data-value="value.value" class="selected-filter">
            {{ value.display_name }} <button type="button" @click="deleteEvent(value.value)" class="filter-remove"></button>
        </div>

        <div data-clear-all @click="deleteAll()" v-if="Object.keys($root.selectedFacets.getFilters()).length > 0" :class="{ loaded: loaded }">
          <div class="skeleton loading-item text" v-if="!loaded"></div>
          <span class="clear-text">Clear All</span>
      </div>
    </div>
</template>

<script>
export default {
  name: "SelectedFilters",
  emits: ['facetChange'],
  props: {
    loaded: {
      default: false,
      type: Boolean
    },
    selected_filters: {
      default: true,
      type: Boolean
    },
    facets: {
      type: Array,
      required: true
    },
  },
  computed: {
    selected_facet() {
      return !!window.location.search
    }
  },
  methods: {
    deleteAll() {
      Array.from(document.querySelectorAll("li.item.active"))
          .forEach(element => element.classList.remove('active'));

      let selected_filters = document.querySelectorAll('.selected-filter');
      for (const filter of selected_filters) {
        let parent = filter.getAttribute('data-value');
        this.$root.selectedFacets.removeFilter(parent);
      }
      this.$root.selectedFacets.updateUrl();
      this.$emit('facetChange');
    },
    deleteEvent(filter) {
      this.$root.selectedFacets.removeFilter(filter);
      this.$root.selectedFacets.updateUrl();
      this.$emit('facetChange');
    },
    getFilterDisplayName(facets, selectedFacets) {
      let formattedFacets = []
      for (const selectedFacet in selectedFacets) {
        let selectedKey = selectedFacet.split(':')[0]
        let selectedValue = selectedFacet.split(':')[1]
        let foundFacet = facets.filter(function(facet) {
          return facet.name === selectedKey
        });

        let display_name = foundFacet[0] ?
            foundFacet[0].options.filter(function(foundFacet) { return foundFacet.value === selectedValue })
            :
            '';

        if (display_name[0]) {
          formattedFacets.push({ display_name: display_name[0].display_name, value: selectedFacet  });
        }
      }
      return formattedFacets
    }
  }
}
</script>

<style scoped>
.selected-filters {
  margin-bottom: 20px;
  margin-top: 10px;
}
.selected-filter {
    background-color: rgba(143, 195, 234, 0.5);
    border-radius: 30px;
    display: inline-block;
    padding: 7.5px 40px 7.5px 20px;
    position: relative;
}
.selected-filter:not(:last-child) {
    margin-right: 6px;
    margin-bottom: 8px;
}
.selected-filter:nth-last-child(2) {
    margin-right: 12px;
}
.filter-remove {
    all: unset; /* unbutton the button */
    width: 30px;
    height: 30px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMTYyOTEgNS41TDEwLjIwNjUgOS41NDM1NEMxMC4zODk1IDkuNzI2NiAxMC4zODk1IDEwLjAyMzQgMTAuMjA2NSAxMC4yMDY1QzEwLjAyMzQgMTAuMzg5NSA5LjcyNjYgMTAuMzg5NSA5LjU0MzU0IDEwLjIwNjVMNS41IDYuMTYyOTFMMS40NTY0NiAxMC4yMDY1QzEuMjczNCAxMC4zODk1IDAuOTc2NjAyIDEwLjM4OTUgMC43OTM1NDQgMTAuMjA2NUMwLjYxMDQ4NSAxMC4wMjM0IDAuNjEwNDg1IDkuNzI2NiAwLjc5MzU0NCA5LjU0MzU0TDQuODM3MDkgNS41TDAuNzkzNTQ0IDEuNDU2NDZDMC42MTA0ODUgMS4yNzM0IDAuNjEwNDg1IDAuOTc2NjAyIDAuNzkzNTQ0IDAuNzkzNTQ0QzAuOTc2NjAyIDAuNjEwNDg1IDEuMjczNCAwLjYxMDQ4NSAxLjQ1NjQ2IDAuNzkzNTQ0TDUuNSA0LjgzNzA5TDkuNTQzNTQgMC43OTM1NDRDOS43MjY2IDAuNjEwNDg1IDEwLjAyMzQgMC42MTA0ODUgMTAuMjA2NSAwLjc5MzU0NEMxMC4zODk1IDAuOTc2NjAyIDEwLjM4OTUgMS4yNzM0IDEwLjIwNjUgMS40NTY0Nkw2LjE2MjkxIDUuNVoiIGZpbGw9IiMxMjI3M0EiLz4KPC9zdmc+Cg==);
    background-size: 11px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
[data-clear-all]:not(.loaded) .clear-text {
    display: none;
}
[data-clear-all] {
    color: var(--color-black);
    cursor: pointer;
    display: inline-block;
    transition: color .3s ease;
    padding: 7.5px 0;
    margin: 0 5px;
}
[data-clear-all]:hover {
    color: #464646;
}
.selected-filters:not(.loaded) .selected-filter {
    display: none;
}
.skeleton.text {
    height: 20px;
    width: 60px;
}
.skeleton.pill {
    height: 33px;
    width: 150px;
    border-radius: 33px;
}
/* Mobile Only */
@media only screen and (max-width: 1023px) {
    .selected-filters {
      margin-top: 0;
      margin-bottom: 10px;
      order: 4;
      padding: 10px 15px;
    }
    .selected-filter {
        margin-bottom: 10px;
    }
}
</style>
