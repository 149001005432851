<template>
  <ul class="giftcards">
    <GiftCard
      v-for="(giftCard, index) in giftCards"
      :giftCard="giftCard"
      :key="index"
    />
  </ul>
</template>

<script>
import GiftCard from '@/components/giftcards/GiftCard.vue';

export default {
  name: "GiftCards",
  components: {
    GiftCard: GiftCard
  },
  props: {
    giftCards: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped>
.giftcards {
  /* Undo UA list styles */
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  /* App Styles */
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 22px;
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {
  .giftcards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 66px;
  }
}
</style>
