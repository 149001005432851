<template>
  <div class="no-results-container">
    <h1 class="search-description">
      <span class="num-results">0 Results </span><span class="search-term">"{{ searchTerm }}"</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "NoResults",
  props: {
    searchTerm: {
      type: String,
      required: true
    },
  },
  mounted() {
    document.querySelector('.search-visual-categories')?.classList.remove('hidden');
  },
  unmounted() {
    document.querySelector('.search-visual-categories')?.classList.add('hidden');
  }
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .no-results-container {
    border-top: 0.5px solid rgba(0, 0, 0, 0.3);
    margin-top: 12px;
    padding-top: 22px;
  }
}

.search-description {
  color: var(--color-black);
  font-size: calc(var(--font-size-large) + 2px);
  line-height: 24px;
  margin-bottom: 19px;
  text-align: center;
}
.search-description .num-results {
  color: var(--color-dark-gray);
  font-weight: var(--font-weight-bold);
}
.search-description .search-term {
  font-weight: var(--font-weight-bold);
}
@media only screen and (max-width: 768px) {
  .search-description .search-term {
    display: block;
  }
}
</style>
