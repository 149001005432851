<template>
  <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5651 12.0313L13.1471 22.3021C13.5621 22.7048 13.5621 23.3577 13.1471 23.7605C12.7322 24.1632 12.0595 24.1632 11.6445 23.7605L0.311199 12.7605C-0.103733 12.3577 -0.103733 11.7048 0.311199 11.3021L11.6445 0.302046C12.0595 -0.100682 12.7322 -0.100682 13.1471 0.302046C13.5621 0.704775 13.5621 1.35773 13.1471 1.76046L2.5651 12.0313Z"
          fill="#CFD3D7"
    />
  </svg>
</template>

<script>
export default {
  name: "ScrollArrow"
}
</script>
