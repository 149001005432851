<script setup>
import { useCssModule } from "vue";
import { itemsToShow } from "@/composables/facets/itemsToShow.js";
import { facetProps } from "@/composables/facets/facetProps.js";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";
import FacetTitle from "@/components/facets/partials/FacetTitle.vue";
import ShowMoreButton from "@/components/facets/partials/ShowMoreButton.vue";

const props = defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();

const optionModel = facetOptionModel(emit);
const showMore = defineModel({type: Boolean, default: false});
</script>

<template>
  <div>
    <FacetTitle
      :displayName="displayName"
      :name="props.name"
      :model="optionModel"
    />

    <ul :class="$styles.list">
      <li
        v-for="option in itemsToShow(options, data?.number_filter_options, data?.max_number_filter_options, showMore)"
        :key="option.value"
        :class="[
          'list-item',
          {large: option.display_name.length > 5}
        ]"
      >
        <label :class="$styles['item-label']">
          <input
            :value="name + ':' + option.value"
            v-model="optionModel"
            type="checkbox"
          />

          {{ option.display_name}}
        </label>
      </li>
    </ul>

    <ShowMoreButton
      v-if="data?.max_number_filter_options > data?.number_filter_options"
      v-model="showMore"
    />
  </div>
</template>

<style module src="@/css/facets.css" />

<style scoped>
/* @todo: Define config? */
.facet.length {
  .list-item {
    grid-column: span 2;
  }
}

/* @todo: Define config? */
.facet.fit {
  ul {
    @media (min-width: 1024px) {
      row-gap: 1.5em;
    }
  }

  .list-item {
    grid-column: span 4;
  }
}

ul {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 0.5em;

  @media (min-width: 1024px) {
    row-gap: 1em;
  }
}

.list-item {
  /* Magento Core Styles
    https://github.com/magento/magento2/blob/b57e30c9ae27e513da830ad1d3b20c6a94afa0e7/lib/web/css/source/lib/_typography.less#L384
  */
  margin-bottom: 0;
}

.list-item.large {
  grid-column: span 2;
}

label {
  border-radius: 9999px;
  border: solid 1px var(--color-gray);
  justify-content: center;
  min-height: 35px;
  padding: 5px 10px;
  position: relative;

  &:hover {
    border-color: var(--color-black);
    outline: solid 1px var(--color-black);
  }
}

label:has(input[type="checkbox"]:checked) {
  border: solid 2px var(--color-black);
}

label:has(input[type="checkbox"]:focus-visible) {
  outline: var(--brand-primary) auto 1px;
}

input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
</style>
