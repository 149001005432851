<script setup>
import { ref, computed } from "vue";

const props = defineProps([
  "primaryImage",
  "secondaryImage",
  "productName",
  "productId",
  "index",
]);

defineEmits([
  "loading",
  "loaded"
]);

const hovered = ref(false);

const activeImage = computed(() =>
  applyImagePlaceholder(
    hovered.value ? props.secondaryImage : props.primaryImage
  )
);

function applyImagePlaceholder(image) {
  let url = new URL(image);

  if (!url.origin.includes("cloudinary")) {
    return "https://res.cloudinary.com/scrubsandbeyond/image/upload/c_pad,e_bgremoval,f_auto,h_600,q_auto,w_429/b_rgb:f3f3f3/search/placeholder.jpg";
  }

  return image;
}
</script>

<template>
  <img
    class="product-image"
    :alt="productName"
    :loading="index <= 3 ? 'eager' : 'lazy'"
    :src="activeImage"
    :title="productName"
    @load="$emit('loaded', productId)"
    v-on="
      secondaryImage
        ? {
            mouseenter: () => {
              hovered = true;
              $emit('loading', productId);
            },
            mouseleave: () => {
              hovered = false;
              $emit('loading', productId);
            },
          }
        : {}
    "
  />
</template>

<style>
.product-image {
  aspect-ratio: 5/7;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .product-image {
    max-width: none;
    width: calc(100% + 15px);
  }
  .product-item:nth-child(odd) .product-image {
    margin-left: -15px;
  }
  .product-item:nth-child(even) .product-image {
    margin-right: -15px;
  }
  .per-row-1 .product-image {
    margin-left: -15px;
    margin-right: -15px;
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
  }
}
</style>
