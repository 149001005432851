<template>
  <template v-if="giftCard()">
    <Breadcrumbs :crumbs="crumbs" :loaded="loaded" />
    <GiftCards
      :giftCards="products"
    />
  </template>
  <template v-else-if="loaded && numResults === 0">
    <NoResults
      :searchTerm="this.$root.selectedFacets.getSearchTerm()"
    />
  </template>
  <template v-else>
    <Breadcrumbs :crumbs="crumbs" :loaded="loaded" />
    <SelectedFilters :facets="facets" :loaded="loaded" @facetChange="call" v-if="(isMobile && Object.keys(this.getFilters()).length > 0) || !isMobile" />
    <Facets
      :facets="facets"
      :loaded="loaded"
      :sort_options="sort_options"
      :visible="facetsVisible"
      ref="facets"
      @facetChange="call"
      @hideFacets="handleHideFacets"
    />

    <template
      v-for="(content, index) in refinedContent"
      :key="index"
    >
      <Content
        :content="content"
        v-if="(content.data.placement === 'top' && loaded)"
      />
    </template>

    <RibbonBanner v-if="showCategoryBanner()" :loaded="loaded" />
    <BrandBar v-if="showBrandBar && loaded" @facetChange="call" />
    <QuickFilters
      v-if="!showBrandBar && (!isSearch || (isSearch && numResults > getSrpQuickFilters()))"
      :facets="facets"
      :loaded="loaded"
      @facetChange="call"
    />
    <Products
      :displayName="displayName"
      :isSearch="isSearch"
      :loaded="loaded"
      :numResults="numResults"
      :products="products"
      :refinedContent="refinedContent"
      :searchTerm="this.$root.selectedFacets.getSearchTerm()"
      :sort_options="sort_options"
      :tracking="tracking"
      @facetChange="call"
      @product-click="productClick"
      @toggleFilters="handleToggleFilters()"
    />
    <Pager v-if="numResults > perPage" @page-change="call" :numResults="numResults" :currentPage="this.$root.selectedFacets.getPage()" :pageVar="pageVar" :perPage="perPage" :loaded="loaded" />
  </template>
  <GoogleAnalytics ref="googleAnalytics" :products="products" :pageVar="pageVar" :perPage="perPage" />
</template>

<script>

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';

import BrandBar from './brandbar/BrandBar.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import Facets from './Facets.vue';
import Content from '@/components/Content.vue';
import GiftCards from '@/components/giftcards/GiftCards.vue';
import GoogleAnalytics from "@/components/GoogleAnalytics.vue";
import NoResults from './NoResults.vue';
import Pager from './Pager.vue';
import Products from './Products.vue';
import QuickFilters from './QuickFilters.vue';
import RibbonBanner from './RibbonBanner.vue';
import SelectedFilters from './SelectedFilters.vue';


window.search = window.search || {};

export default {
  name: "Search",
  components: {
    BrandBar: BrandBar,
    Breadcrumbs: Breadcrumbs,
    Content: Content,
    Facets: Facets,
    GiftCards: GiftCards,
    GoogleAnalytics: GoogleAnalytics,
    NoResults: NoResults,
    Pager: Pager,
    Products: Products,
    QuickFilters: QuickFilters,
    RibbonBanner: RibbonBanner,
    SelectedFilters: SelectedFilters
  },
  data() {
    return {
      crumbName: '',
      displayName: '',
      facets: [],
      groups: [],
      isSearch: false,
      loaded: false,
      facetsVisible: false,
      numResults: 0,
      pageVar: 'p',
      perPage: 36,
      products: [],
      refinedContent: [],
      sort_options: [],
      tracking: {}
    }
  },
  computed: {
    showBrandBar: function() {
      return !!window.search.categoryBrand?.attribute_id;
    },
    crumbs: function() {
      let crumbs = [];

      if (window.search.categoryFilter) {
        let filterType = window.search.categoryFilter.persistent_filter_type_attribute_code || '';
        if (filterType !== 'collection_id') {
          crumbs.push({
            'display_name': 'Shop By Color',
            'href': '/shop-by-scrubs-color',
          });
        }

        let displayName = window.search.categoryFilter.persistent_filter_type_attribute_option_label || '';
        if (filterType === 'collection_id') {
          displayName = this.crumbName;
        }

        crumbs.push({
          'display_name': displayName
        });
      } else {
        crumbs = this.groups;
      }

      return crumbs;
    },
    isMobile: function() {
        return window.matchMedia('(max-width: 1023px)').matches;
    }
  },
  created() {
    window['cnstrc-data'] = window['cnstrc-data'] || {};

    this.call();
  },
  methods: {
    giftCard() {
      return window.search.filter === '793';
    },
    // All events call this method to determine if search or browse should be called
    call() {
      let path = window.location.pathname;
      let search = window.location.search;

      if (search.includes('quiz')) {
        this.quiz();
        return;
      }

      if (path.includes('/catalogsearch/result/')) {
        this.search();
        return;
      }

      if (window.search.categoryFilter) {
        this.browse(
          window.search.categoryFilter.persistent_filter_type_attribute_code,
          window.search.categoryFilter.persistent_filter_type_attribute_option_label
        );
        return;
      }

      this.browse();
    },
    // Constructor Browse
    browse(filterName = 'group_id', filterValue = window.search.filter) {
      let self = this,
          browse = this.getSearchClient().browse;

      let variationsMap = {
        "group_by": [
          {
            "name": "color",
            "field": "data.color"
          },
        ],
        "values": {
          "image": {
            "aggregation": "first",
            "field": "data.image_url"
          },
          "alter_image": {
            "aggregation": "first",
            "field": "data.alter_image_url"
          },
        },
        "dtype": "object"
      };

      browse.getBrowseResults(
        filterName,
        filterValue,
        {
          filters: this.getFilters(),
          page: this.$root.selectedFacets.getPage(),
          resultsPerPage: this.perPage,
          sortBy: this.getSortBy(),
          sortOrder: this.getSortOrder(),
          variationsMap: variationsMap
        }
      ).then(function (json) {
        self.setConstructorTrackingData(json);
        self.groups = json.response.groups || [];
        self.facets = json.response.facets || [];
        self.products = json.response.results || [];
        self.numResults = json.response.total_num_results;
        self.refinedContent = json.response.refined_content;
        self.sort_options = json.response.sort_options;
        if (filterName === 'group_id') {
          self.displayName = json.response.groups[0]?.display_name || '';
        } else if (filterName === 'collection_id') {
          self.crumbName = json.response.collection?.display_name || '';
        } else {
          self.displayName = filterValue + ' Scrubs';
        }
        self.loaded = true;
        self.isSearch = false;
        self.tracking = {
          filterName: filterName,
          filterValue: filterValue
        };
      });
    },
    // Get Filters to send to Constructor
    getFilters() {
      let rawFilters = this.$root.selectedFacets.getFilters();
      let parsedFilters = {};

      Object.values(rawFilters).forEach(function (value) {
        let v = value.split(':');
        parsedFilters[v[0]] = parsedFilters[v[0]] || [];
        parsedFilters[v[0]].push(v[1]);
      });

      return parsedFilters;
    },
    getSortOrder() {
      let sortOptions = this.$root.selectedFacets.getSortOptions();
      return sortOptions.sortOrder ?? 'descending';
    },
    getSortBy() {
      let sortOptions = this.$root.selectedFacets.getSortOptions();
      return sortOptions.sortBy ?? 'relevance';
    },
    // Get Constructor Search Client
    getSearchClient() {
      let searchClientConfig = {
        apiKey: window.search.apiKey,
        fetch: fetch
      };

      if (window.cnstrcUserId) {
        searchClientConfig.userId = window.cnstrcUserId;
      } else {
        let mageCacheStorage = JSON.parse(localStorage.getItem('mage-cache-storage'));

        if (mageCacheStorage?.customer?.id) {
          searchClientConfig.userId = mageCacheStorage.customer.id;
        }
      }

      return new ConstructorIOClient(searchClientConfig);
    },
    // Product Click Google Analytics
    productClick(product, position) {
      this.$refs.googleAnalytics.productClick(product, position);
    },
    // Constructor Search
    search() {
      let self = this,
          search = this.getSearchClient().search,
          searchTerm = this.$root.selectedFacets.getSearchTerm();

      if (searchTerm !== null) {
        search.getSearchResults(
          searchTerm,
          {
            filters: this.getFilters(),
            page: this.$root.selectedFacets.getPage(),
            resultsPerPage: this.perPage,
            sortBy: this.getSortBy(),
            sortOrder: this.getSortOrder(),
          }
        ).then(function (json) {
          if (json.response.redirect) {
            window.location.replace(json.response.redirect.data.url);
            return;
          }

          self.setConstructorTrackingData(json);
          self.groups = [{display_name: "Search Results"}]
          self.facets = json.response.facets || [searchTerm];
          self.products = json.response.results || [];
          self.sort_options = json.response.sort_options;
          self.refinedContent = json.response.refined_content;
          self.numResults = json.response.total_num_results;
          self.displayName = json.response.groups[0]?.display_name || '';
          self.loaded = true;
          self.isSearch = true;
        });
      }
    },
    // Update Facet Values with selected values
    updateFacets(selectedFacets) {
      Object.entries(selectedFacets).forEach(function ([key, selectedFacet]) {
        selectedFacet.forEach(function (value) {
          this.$root.selectedFacets.setFilter(key + ':' + value);
        }, this);
      }, this);
    },
    setConstructorTrackingData(json) {
      window['cnstrc-data'].request = json.request;
      window['cnstrc-data'].result_id = json.result_id;
    },
    getSrpQuickFilters() {
      return Number(window?.search?.srpQuickFilters) || 10;
    },
    showCategoryBanner() {
      return (window?.search?.categoryBanner?.text && window?.search?.categoryBanner?.color);
    },
    handleToggleFilters() {
      this.$refs.facets.$el.showModal();
      this.facetsVisible = true;
    },
    /**
     * dialog.close() will set display: none. The W3C is still working on the
     * spec for exit animations.  If we want the dialog to slide out; we have
     * to get creative.  is-hiding will animate the dialog off screen, and the
     * event listener will handle the .close() call once the animation finishes
     * */
    handleHideFacets() {
      this.$refs.facets.$el.classList.add('is-hiding');
      this.$refs.facets.$el.addEventListener(
        "animationend",
        () => {
          this.$refs.facets.$el.classList.remove("is-hiding");
          this.$refs.facets.$el.close();
          this.facetsVisible = false;
        },
        {
          once: true,
          capture: false,
        }
      );
    },
    quiz() {
      let self = this,
          quiz = this.getSearchClient().quizzes;

      let searchParams = new URLSearchParams(window.location.search);
      let quizId = searchParams.get('quiz');
      searchParams.delete('quiz');
      let spo = Object.fromEntries(searchParams);
      let pspo = {};

      Object.entries(spo).forEach(entry => {
        pspo[entry[0]] = entry[1].split(',');
      });

      let ans = Object.values(pspo);

      quiz.getQuizResults(
        quizId,
        {
          answers: ans,
          filters: this.getFilters(),
          page: this.$root.selectedFacets.getPage(),
          resultsPerPage: this.perPage,
          sortBy: this.getSortBy(),
          sortOrder: this.getSortOrder(),
        }
      ).then(function (json) {
        if (json.response.redirect) {
          window.location.replace(json.response.redirect.data.url);
          return;
        }

        self.setConstructorTrackingData(json);
        self.groups = [{display_name: "Search Results"}]
        self.facets = json.response.facets || [];
        self.products = json.response.results || [];
        self.sort_options = json.response.sort_options;
        self.refinedContent = json.response.refined_content;
        self.numResults = json.response.total_num_results;
        self.displayName = json.response.groups[0]?.display_name || '';
        self.loaded = true;
        self.isSearch = true;
      });

    }
  }
}
</script>

<style>
@keyframes pulse-bg {
  0% { background-color: #F3F3F3; }
  50% { background-color: #e8e8e8; }
  100% { background-color: #F3F3F3; }
}
.skeleton.loaded,
.loaded .skeleton {
  display: none;
}
.skeleton {
  animation: pulse-bg 1s infinite;
}

#search_browse *:focus-visible {
  /* Magento Core Styles
     https://github.com/magento/magento2/blob/b57e30c9ae27e513da830ad1d3b20c6a94afa0e7/lib/web/css/source/lib/_resets.less#L93
  */
  outline: var(--brand-primary) auto 1px;
}
</style>
