import { computed } from 'vue';
import selectedFacets from '@/store/selectedFacets';

export default function facetOptionModel(emit) {
  return computed({
    get: function () {
      let selected = [];
      let filters = selectedFacets.getFilters();

      Object.values(filters).forEach(function (value) {
        selected.push(value);
      });

      return selected;
    },
    set: function(value) {
      selectedFacets.clearFilters();

      value.forEach(function (elementValue) {
        selectedFacets.setFilter(elementValue);
      }, this);

      selectedFacets.updateUrl();
      emit('facetChange');
    }
  });
};
