<script setup>
import { useCssModule } from "vue";
import { facetProps } from "@/composables/facets/facetProps.js";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";

defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();

const optionModel = facetOptionModel(emit);
</script>

<template>
  <div>
    <div
      class="toggle"
      v-for="option in options.filter((option) => option.value === 'True')"
      :key="option.value"
    >
      <label :class="[
        'switch',
        $styles['item-label']
      ]">
        <input
          :value="name + ':' + option.value"
          v-model="optionModel"
          type="checkbox"
        />
        <span class="slider"></span>

        {{ displayName }}
      </label>
    </div>
  </div>
</template>

<style>
:root {
 --border-color-gray: #C4C4C4;
}
</style>

<style module src="@/css/facets.css" />

<style scoped>
.toggle {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
}

.switch input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.slider {
  background: var(--border-color-gray);
  border-radius: 9999px;
  cursor: pointer;
  height: 30px;
  inset: 0;
  position: relative;
  transition: .4s;
  width: 60px;
  margin-right: 14px;

  &:before {
    background-color: var(--color-white);
    border-radius: 50%;
    bottom: 3px;
    content: "";
    height: 24px;
    left: 3px;
    position: absolute;
    transition: .4s;
    width: 24px;
  }
}

input:checked + .slider {
  background-color: var(--brand-primary);

  &:before {
    transform: translateX(29px);
  }
}

input:focus-visible + .slider {
  box-shadow: 0 0 5px var(--brand-primary);
}
</style>
