<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Toggle Items Per Row</title>
    <rect y="0.101562" width="8" height="8" rx="1" transform="rotate(-0.320603 0 0.101562)" fill="#797979"/>
    <rect x="0.0566406" y="10.1016" width="8" height="8" rx="1" transform="rotate(-0.320603 0.0566406 10.1016)" fill="#797979"/>
    <rect x="10" y="0.0454102" width="8" height="8" rx="1" transform="rotate(-0.320603 10 0.0454102)" fill="#797979"/>
    <rect x="10.0566" y="10.0454" width="8" height="8" rx="1" transform="rotate(-0.320603 10.0566 10.0454)" fill="#797979"/>
  </svg>
</template>

<script>
export default {
  name: "Toggle2"
}
</script>
