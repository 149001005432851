<template>
  <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8933 12.0312L0.311241 1.76044C-0.103691 1.35772 -0.103691 0.704763 0.311241 0.302034C0.726172 -0.100694 1.39891 -0.100694 1.81384 0.302033L13.1472 11.302C13.5621 11.7048 13.5621 12.3577 13.1472 12.7604L1.81384 23.7605C1.39891 24.1632 0.726174 24.1632 0.311243 23.7605C-0.103689 23.3577 -0.103689 22.7048 0.311243 22.302L10.8933 12.0312Z"
          fill="#CFD3D7"
    />
  </svg>
</template>

<script>
export default {
  name: "ScrollArrowRight"
}
</script>
