<template>
  <div class="quick-filters">
    <div class="skeleton-filters" v-if="!loaded">
      <div :class="{ loaded: loaded }" v-for="skeleton in 12" class="quick-filter skeleton loading-item" :key="skeleton"></div>
    </div>
    <template v-else>
    <div class="quick-filter all" :class="{ active: selectedFacets.length === 0}">
      <span @click="allClickHandler()" class="quick-filter-label">All</span>
    </div>
    <div v-for="(qf) in quickFilters" :key="qf.name" class="quick-filter">
      <input
        :id="'qf-' + qf.filter.value"
        :value="qf.facet + ':' + qf.filter.value"
        type="checkbox"
        v-model="selectedFacets"
      />

      <label :for="'qf-' + qf.filter.value" class="quick-filter-label" :data-text="qf.filter.display_name">
          {{ qf.filter.display_name }}
      </label>
    </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "QuickFilters",
  emits: ['facetChange'],
  props: {
    facets: {
      type: Array,
      required: true
    },
    loaded: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    selectedFacets: {
      get() {
        return Object.values(this.$root.selectedFacets.getFilters());
      },
      set(values) {
        let selected = Object.values(this.$root.selectedFacets.getFilters());
        let deletes = selected.filter(s => !values.includes(s));

        deletes.forEach(function(value) {
          this.$root.selectedFacets.removeFilter(value);
        }, this);

        values.forEach(function(value) {
          this.$root.selectedFacets.setFilter(value);
        }, this);

        this.$root.selectedFacets.updateUrl();
        this.$emit('facetChange');
      }
    },
    quickFilters: function () {
      /**
       * This is the v1 order
       * Collection #1
       * Color (Not Group) #1
       * Style #1
       * 4 Stars +
       * Collection #2
       * Color (Not Group) #2
       * Style #2
       * Collection #3
       * Color (Not Group) #3
       */
      let quickFilters = [];

      /* facetsToFilter is sorted in the order the business has asked. */
      let facetsToFilter = [
        'brand_collection',
        'color',
        'item_style'
      ];

      /**
       * Reduce the amount of data we need from the facets prop to just the ones
       * we're going to display, then sort the array into the same order as
       * facetsToFilter
       */
      let facets = this.facets.filter(facet => facetsToFilter.includes(facet.name)).sort( (a, b) => {
        return facetsToFilter.indexOf(a.name) - facetsToFilter.indexOf(b.name);
      });

      for(let i = 0; i < 3; i++) {
        facets.forEach(facet => {
          /* This check prevents the 3rd item style from being added */
          if (i === 2 && facet.options[i] && facet.options[i].name === 'item_style') {
            return;
          }

          if (facet.options[i]) {
            quickFilters.push({
              name: facet.options[i].display_name,
              facet: facet.name,
              filter: {
                display_name: facet.options[i].display_name,
                value: facet.options[i].value,
              }
            });
          }
        });

        /* This check adds the 4 Stars + Rating */
        if (i === 0) {
          let rating = this.facets.filter(facet => facet.name === 'average_rating')[0];

          /* .options[1] is assuming descending rating being passed in */
          if (rating) {
            quickFilters.push({
              name: rating.options[1].display_name,
              facet: rating.name,
              filter: {
                display_name: rating.options[1].display_name,
                value: rating.options[1].value,
              }
            });
          }
        }
      }

      return quickFilters;
    },
  },
  methods: {
    allClickHandler: function() {
      this.selectedFacets.forEach(function(value) {
        this.$root.selectedFacets.removeFilter(value);
      }, this);

      this.selectedFacets = [];

      this.$root.selectedFacets.updateUrl();
      this.$emit('facetChange');
    }
  }
}
</script>

<style scoped>
.skeleton-filters,
.quick-filters {
  width: 100%;
  overflow: scroll;
  display: inline-flex;
  padding: 0 15px;
}
.quick-filters::-webkit-scrollbar {
  display: none
}
.quick-filter {
    display: inline-block;
    margin-right: 10px;
}
.quick-filter:last-child {
  margin-right: 0;
}
.quick-filter-label {
    align-items: center;
    border-radius: 5px;
    border: 1px solid #cfd3d7;
    color: var(--color-black);
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.5;
    padding: 3.5px 15px;
    transition: border-color 0.3s ease;
    white-space: nowrap;
}
.quick-filter-label::after {
  content: attr(data-text);
  content: attr(data-text) / "";
  font-weight: var(--font-weight-bold);
  height: 0;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  visibility: hidden;
}

@media speech {
  .quick-filter-label::after {
    display: none;
  }
}

.quick-filters input {
    display: none;
}
.all.active .quick-filter-label,
.quick-filter-label:hover,
.quick-filters input:checked + .quick-filter-label {
  border-color: var(--color-black);
  font-weight: var(--font-weight-bold);
}
.quick-filter.skeleton {
  width: 60px;
  height: 30px;
  border-radius: 5px;
}
.quick-filter.skeleton:first-child,
.quick-filter.skeleton:nth-child(4),
.quick-filter.skeleton:nth-child(7) {
  width: 100px;
}
.quick-filter.skeleton:nth-child(3),
.quick-filter.skeleton:nth-child(9),
.quick-filter.skeleton:nth-child(10),
.quick-filter.skeleton:nth-child(12) {
  width: 180px;
}

/* Desktop Only */
@media only screen and (min-width: 1024px) {
    .quick-filters {
      margin-left: 60px;
      width: calc(78% - 60px);
      float: right;
      overflow: hidden;
      flex-wrap: wrap;
      max-height: 40px;
      padding: 0;
    }
    .skeleton-filters {
      padding: 0;
    }
    .quick-filter {
      margin-bottom: 10px;
    }
}

/* Mobile Only */
@media only screen and (max-width: 1024px) {
  .quick-filters {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    padding: 0 24px 17px;
    margin-bottom: 21px;
    order: 3;
  }
}
</style>
