<template>
  <div class="toggle-wrap">
    <div data-per-row="2"
      :class="[
        { active: togglePerRow !== 1 },
        'per-row-toggle'
      ]"
      @click="$emit('toggleChange', togglePerRow !== 1 ? 1 : 2)"
    >
      <Toggle2 />
    </div>
  </div>
</template>

<script>
import Toggle2 from "@/components/icon/Toggle2.vue";

export default {
  name: "MobilePerRow",
  components: {
    Toggle2
  },
  props: {
    togglePerRow: {
      type: Number,
      required: true,
      default: 2
    }
  },
  emits: ['toggleChange']
}
</script>

<style scoped src="./../css/per-row-toggle.css"></style>
