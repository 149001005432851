<template>
  <div v-if="sort_options" name="sort-by" id="sort_by" :class="{ active: listboxExpanded }">
    <button type="button"
      :aria-expanded="String(listboxExpanded)"
      @click="toggleListbox"
      @keydown.up.down.prevent="checkShow"
      @mousedown="mouseDownHandler($event)"
      aria-haspopup="listbox"
      aria-labelledby=""
      class="filter-options-title"
      ref="button"
    >
      {{ label }} <span class="currently-selected">{{ currentlySortedBy()?.display_name }}</span>
    </button>

    <ul
      :aria-activedescendant="listboxExpanded ? focusdescendant : activedescendant"
      :aria-hidden="String(!listboxExpanded)"
      @blur="blurHandler($event)"
      @focus="setupFocus($event)"
      @keydown.enter.escape.space.prevent="checkHide"
      @keydown.prevent="checkKeyPress($event)"
      aria-labelledby=""
      class="listbox"
      ref="listbox"
      role="listbox"
      tabindex="-1"
    >
      <li v-for="value in sort_options"
        :aria-selected="String(('sort_by_' + value.sort_by) === focusdescendant)"
        :class="{ focused: ('sort_by_' + value.sort_by) === focusdescendant }"
        :id="'sort_by_' + value.sort_by"
        :key="value.sort_by"
        :sort_by="value.sort_by"
        :sort_order="value.sort_order"
        @click="changeSortBy(value.sort_order, value.sort_by)"
        role="option"
      >
        {{ value.display_name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Listbox",
  emits: ["facetChange"],
  props: {
    label: {
      type: String,
      required: true
    },
    sort_options: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      focusdescendant: false,
      listboxExpanded: false,
    }
  },
  computed: {
    activedescendant: function() {
      return this.currentlySortedBy()?.sort_by ? "sort_by_" + this.currentlySortedBy()?.sort_by : '';
    }
  },
  methods: {
    blurHandler(e) {
      if (e.relatedTarget !== this.$refs.button) {
        this.checkHide();
      }
    },
    currentlySortedBy: function () {
      return this.sort_options.filter(opt => opt.status === 'selected').pop()
    },
    toggleListbox: function() {
      if (this.listboxExpanded) {
        this.checkHide();
      } else {
        this.checkShow();
      }
    },
    // This handler is just for Safari and it's a hack to get Safari to behave
    // similarly to Chrome/Firefox.  This makes it so that when `blurHandler`
    // runs the relatedTarget property is set to the button instead of null.
    mouseDownHandler: function(event) {
      event.preventDefault();
    },
    changeSortBy(sortOrder, sortBy) {
      this.$root.selectedFacets.setSortOptions({
        'sortOrder': sortOrder,
        'sortBy': sortBy,
      });
      this.$root.selectedFacets.updateUrl();
      this.$emit('facetChange');

      this.listboxExpanded = false;
    },
    checkShow: function() {
      this.listboxExpanded = true;
      this.$refs.listbox.focus();
    },
    checkHide: function() {
      this.listboxExpanded = false;
      this.$refs.button.focus();
    },
    setupFocus: function () {
      if (this.activedescendant) {
        this.focusdescendant = this.activedescendant;
        return;
      }

      this.focusFirstItem();
    },
    focusFirstItem: function() {
      let firstItem;

      firstItem = this.$refs.listbox.querySelector('[role="option"]');

      if (firstItem) {
        this.focusItem(firstItem);
      }
    },
    focusLastItem: function() {
      let itemList = this.$refs.listbox.querySelectorAll('[role="option"]');

      if (itemList.length) {
        this.focusItem(itemList[itemList.length - 1]);
      }
    },
    focusItem: function(element) {
      this.focusdescendant = element.id;
    },
    checkKeyPress: function(e) {
      let next = document.getElementById(this.focusdescendant);
      if (!next) {
        console.log(this.focusdescendant, next);
        return;
      }

      switch(e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          if (e.key === 'ArrowUp') {
            next = next.previousElementSibling;
          } else {
            next = next.nextElementSibling;
          }

          if (next) {
            this.focusItem(next);
          }
          break;
        case 'Home':
          this.focusFirstItem();
          break;
        case 'End':
          this.focusLastItem();
          break;
        case ' ':
        case 'Enter':
          this.changeSortBy(next.getAttribute('sort_order'), next.getAttribute('sort_by'));
          break;
      }
    },
  },
}
</script>

<style scoped>
[id="sort_by"] {
  align-items: center;
  display: flex;
  float: right;
  justify-content: flex-end;
  margin: 25px auto 15px;
  position: relative;
  width: 240px;
  z-index: 3;
}

.filter-options-title {
  /* Undo Buttons */
  border: unset;
  border-radius: unset;
  background: unset;

  /* From Magento */
  /* sabsab/app/design/frontend/MelonTech/sab/Magento_LayeredNavigation/web/css/source/_module.less */
  color: var(--color-black);
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  /* App Styles */
  /* position: relative; */
  font-family: var(--font-family-base);
  font-size: calc(var(--font-size-small) + 1px);
  font-weight: var(--font-weight-normal);
  height: 37px;
  padding: 0 30px 0 0;
  text-transform: none;
}

.filter-options-title::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxOCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTk5MzUgOC4zNDgyOEwxNS44NDY1IDEuMTg5ODVDMTYuMTE1IDAuOTA5MTU5IDE2LjU1MDMgMC45MDkxNTkgMTYuODE4OCAxLjE4OTg1QzE3LjA4NzMgMS40NzA1NCAxNy4wODczIDEuOTI1NjIgMTYuODE4OCAyLjIwNjMxTDkuNDg1NDkgOS44NzI5OEM5LjIxNyAxMC4xNTM3IDguNzgxNyAxMC4xNTM3IDguNTEzMjEgOS44NzI5OEwxLjE3OTg4IDIuMjA2MzFDMC45MTEzOTQgMS45MjU2MiAwLjkxMTM5NCAxLjQ3MDU0IDEuMTc5ODggMS4xODk4NUMxLjQ0ODM3IDAuOTA5MTU5IDEuODgzNjcgMC45MDkxNTkgMi4xNTIxNSAxLjE4OTg1TDguOTk5MzUgOC4zNDgyOFoiIGZpbGw9IiMwRDI5M0UiLz4KPC9zdmc+Cg==');
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 16px;
  content: "";
  height: 10px;
  margin-top: -5px;
  position: absolute;
  right: 7px;
  top: 50%;
  transition: transform .3s ease-in-out;
  width: 16px;
}

[id="sort_by"].active .filter-options-title::after {
  transform: rotate(180deg);
}

.listbox {
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid transparent;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out, border .3s ease-in-out;
}

[id="sort_by"].active .listbox {
  max-height: 400px;
  border: 1px solid var(--color-light-gray);
}

.listbox li {
  color: var(--color-dark-gray);
  cursor: pointer;
  font-size: calc(var(--font-size-small) + 1px);
  padding: 12px 30px;
  text-align: right;
  transition: background-color .3s ease-in-out;
  margin-bottom: 0;
}

.listbox li:hover,
.listbox li.focused {
  background-color: #D6D7D9;
}

.currently-selected {
  color: var(--color-dark-gray);
}
</style>
