<script setup>
import { computed, useCssModule } from "vue";
import { facetProps } from "@/composables/facets/facetProps.js";
import facetOptionModel from "@/composables/facets/facetOptionModel.js";
import FacetTitle from "@/components/facets/partials/FacetTitle.vue";
import ShowMoreButton from "@/components/facets/partials/ShowMoreButton.vue";

facetProps.data = {
  ...facetProps.data,
  default() {
    return {
      max_number_filter_options: 100
    }
  }
}

const props = defineProps(facetProps);
const emit = defineEmits(['facetChange']);
const $styles = useCssModule();

const optionModel = facetOptionModel(emit);
const showMore = defineModel({type: Boolean, default: false});

const searchModel = defineModel('searchModel');

const itemsToShow = computed(() => {
  let filteredOptions = props.options;

  if (searchModel.value?.length > 0) {
    filteredOptions = props.options.filter((option) => {
      return option?.display_name.toLowerCase().includes(searchModel.value.toLowerCase());
    });
  }

  let end = filteredOptions.length;

  if (props.data?.number_filter_options) {
    end = props.data.number_filter_options;
  }

  if (showMore.value) {
    let max = 100;

    if (props.data?.max_number_filter_options) {
      max = props.data.max_number_filter_options;
    }

    end = max;
  }

  return filteredOptions.sort((a, b) => {
    if (a.status === 'selected' && b.status !== 'selected') {
      return -1;
    }
    if (a.status !== 'selected' && b.status === 'selected') {
      return 1;
    }
    return 0;
  }).slice(0, end);
});

const shouldShowMoreButton = computed(() => {
  let shouldShow = props.data?.max_number_filter_options > props.data?.number_filter_options;

  if (searchModel.value?.length) {
    shouldShow = itemsToShow.value?.length > props.data?.number_filter_options;
  }

  return shouldShow;
});

/**
 * This method is used inside of a v-html call, which allows for unsafe HTML
 * output.
 *
 * We control the content of optionDisplayName we're not using the user's search
 * term directly; it is only being used to generate the pattern for the RegExp.
 *
 * This _should_ be safe; but if you make changes to this be sure to test as
 * many user inputs as possible to assure you can't generate an XSS issue.
 */
function highlightSearchTerm(optionDisplayName) {
  let pattern = new RegExp(searchModel.value, 'i');
  return optionDisplayName.replace(pattern, '<span class="search-term">$&</span>');
}
</script>

<template>
  <div>
    <FacetTitle
      :displayName="displayName"
      :name="props.name"
      :model="optionModel"
    />

    <div>
      <input
        type="search"
        v-model="searchModel"
        :placeholder="'Search ' + displayName"
        class="search"
      />

      <div class="not-found" v-show="searchModel?.length && itemsToShow?.length === 0">
        No results for <span class="search-term">{{ searchModel }}</span>.
      </div>
    </div>

    <ul v-if="itemsToShow?.length" :class="$styles.list">
      <li
        v-for="option in itemsToShow"
        :key="option.value"
        :class="$styles['list-item']"
      >
        <label :class="[
          $styles['item-label'],
          $styles['gap-4'],
        ]">
          <input
            :value="name + ':' + option.value"
            :class="$styles.checkbox"
            v-model="optionModel"
            type="checkbox"
          />

          <span class="item-display-name" v-html="highlightSearchTerm(option.display_name)"></span>
        </label>
      </li>
    </ul>

    <ShowMoreButton
      v-if="shouldShowMoreButton"
      v-model="showMore"
    />
  </div>
</template>

<style module src="@/css/facets.css" />

<style scoped>
.search {
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  font-size: var(--font-size-medium);
  height: 46px;
  margin-bottom: 15px;
  padding: 0 0 0 15px;
  width: 100%;

  &:focus-visible {
    border-color: var(--color-black);
    outline: 0;
  }

  &::placeholder {
    color: var(--color-dark-gray);
  }

  /** This is considered a bad practice because it is non-standard but I don't
      think these elements are high-impact enough for us to maintain extra
      features just for Firefox */
  &::-webkit-search-cancel-button {
    appearance: none;
    cursor: pointer;
    margin: 0;
    width: 44px;
    height: 44px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden='true' class='cdr-icon_8.0.4 _354EsGCIFvW3u_0r8EYxCi'%3E%3Cpath d='m13.406 12.006 3.297-3.296a1 1 0 1 0-1.414-1.414l-3.297 3.295-3.285-3.295A1 1 0 1 0 7.293 8.71l3.285 3.295-3.285 3.288a1 1 0 0 0 1.414 1.415l3.285-3.289 3.297 3.289a1 1 0 0 0 1.414-1.415l-3.297-3.287z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 55%;
  }

  /* Force the cancel button to always be visible if the user has entered text.
    Otherwise it is only visible when focused. */
  &:not(:placeholder-shown)::-webkit-search-cancel-button {
    opacity: 1;
  }
}

/* Deep selector needed because .search-term comes from v-html */
.item-display-name :deep(.search-term) {
  font-weight: bold;
}

.not-found .search-term {
  overflow-wrap: anywhere;
}
</style>
