<template>
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Copy Item SKU</title>
    <rect x="3.56797" y="3.56699" width="11.8667" height="15.0334" rx="1.6" fill="white" stroke="#797979" stroke-width="0.8"/>
    <rect x="0.4" y="0.4" width="11.8667" height="15.0334" rx="1.6" fill="white" stroke="#797979" stroke-width="0.8"/>
    <path d="M3.16797 4.75H9.50131" stroke="#797979" stroke-width="0.5"/>
    <path d="M3.16797 7.91699H9.50131" stroke="#797979" stroke-width="0.5"/>
    <path d="M3.16797 11.083H9.50131" stroke="#797979" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: "Copy"
}
</script>
