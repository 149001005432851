<template>
  <div class="products wrapper grid products-grid" :class="{ loaded: loaded }">
    <div v-if="!loaded" class="loading-name skeleton text"></div>

    <h1 class="search-description">
      <template v-if="isSearch">
        <span class="num-results">{{ numResults }} Results For </span><span class="search-term">"{{ searchTerm }}"</span>
      </template>
      <template v-else-if="loaded">
        {{ getCategoryTitle() }}
      </template>
    </h1>

    <div v-if="!loaded" class="toggle skeleton text"></div>

    <div class="products-per-row mobile">
      <div class="flex-container">
        <MobilePerRow
          :togglePerRow="toggle_per_row_mobile"
          @toggleChange="toggleChangeHandlerMobile"
        />
        <div v-if="!isSearchPage()" class="total-items">{{ numResults }} Items</div>
      </div>

      <button type="button" class="filter mobile" @click="$emit('toggleFilters')">Filter &amp; Sort</button>
    </div>

    <div v-if="!isSearchPage()" class="total-items">{{ numResults }} Items</div>

    <Listbox
      :label="'Sort By:'"
      :sort_options="sort_options"
      @facetChange="$emit('facetChange')"
    />

    <ol class="products list items product-items" v-if="!loaded">
      <li :class="{ loaded: loaded }" v-for="skeleton in 8" class="item product product-item" :key="skeleton">
        <div class="aspect-ratio aspect-ratio-3x4 skeleton"></div>
        <div class="product details product-item-details">
          <div class="text skeleton"></div>
          <div class="text skeleton"></div>
          <div class="text short skeleton"></div>
          <div class="text short skeleton"></div>
        </div>
      </li>
    </ol>

    <ol class="products list items product-items" :class="productListClass" v-bind="trackingAttributesForSearchBrowseContainer" v-else>
      <template
        :key="product.data.id ?? 'ad-' + product.data.placement + '-' + product.data.position"
        v-for="(product, index) in cardLoop()"
      >
        <template v-if="product?.data?.id">
          <li
            :data-product-id="product.data.id"
            class="item product product-item"
            :class="{'in-wishlist': inWishlist(product.data.id)}"
            v-bind="trackingAttributesForSearchBrowseResult(product)"
          >
            <div class="product-item-info" :class="{loading: this.loadingProducts[product.data.id] === true }" data-container="product-grid" @click="$emit('productClick', product, index)">
              <span class="loader"></span>
              <a :href="product.data.url" @click="setSwatchCookie(product)" class="product photo product-item-photo" :aria-label="product.value" tabindex="-1">
                <div v-if="promoFlag(product.data) !== ''" v-html="promoFlag(product.data)"></div>
                  <ProductImage
                    :primaryImage="getPrimaryImage(product)"
                    :secondaryImage="getSecondaryImage(product)"
                    :productName="product.value"
                    :productId="product.data.id"
                    :index="index"
                    @loading="(productId) => {
                      this.loadingProducts[productId] = true;
                    }"
                    @loaded="(productId) => {
                      this.loadingProducts[productId] = false;
                    }"
                  />
              </a>
              <div class="product details product-item-details">
                <Swatches
                  :product="product"
                  :selectedSwatches="selectedSwatches"
                  :toggle_per_row_mobile="toggle_per_row_mobile"
                  @swatch-change="(product, swatch) => {
                    this.selectedSwatches[product.data.id] = swatch?.option_id;

                    /**
                     * This check is a workaround for the Wintersun release.
                     * Current logic is flawed and does not set the featured color
                     * as the selected swatch in the app state.  That means if
                     * someone clicks that first swatch the loader will appear
                     * until they pick another swatch.
                     */
                    if (this.productImages[product.data.id] === undefined && swatch?.label === product?.data?.color) {
                      return;
                    }

                    this.loadingProducts[product.data.id] = true;
                    this.productImages[product.data.id] = product?.variations_map?.[swatch?.label];
                  }"
                  @set-swatch-cookie="(product) => setSwatchCookie(product)"
                ></Swatches>

                <div v-if="cprBanner(product.data) !== ''" v-html="cprBanner(product.data)"></div>

                <div class="product name product-item-name">
                  <a class="product-item-link" :href="product.data.url" @click="setSwatchCookie(product)">
                    <span class="item-collection">{{ product.data.brand_collection }}</span>
                    <span class="item-name">{{ productName(product.value, product.data.brand_collection) }}</span>
                  </a>
                </div>
                <div class="price">
                  <strong class="lowest_price">{{ priceRange(product.data.price_range) }}</strong>
                </div>
                <div class="sale-price" v-if="product.data.on_sale" :style="{color: getSalePriceColor()}">
                  Sale from <span class="lowest-price">{{ formatPrice(product.data.special_price) }}</span>
                </div>
                <RatingStars
                  :rating="product.data.average_rating"
                  :reviewCount="product.data.review_count"
                  :url="product.data.url + '#tab-label-product.view.reviews'"
                  />
                <div class="compare-sku" :data-sku="product.data.id" :class="{'incompare': this.compare.includes(product.data.id),
                'compare-true': this.compareT.includes(product.data.id),
                'compare-false': this.compareF.includes(product.data.id)}">
                  <div class="compare">
                    <label class="container">
                      <input v-model="compare" :value="product.data.id" type="checkbox" class="compare-input" @click="toggleCompare(product)">
                      <span class="label-text">Compare</span>
                      <span class="checkmark"></span>
                      <a href="/catalog/product_compare/index/">View List</a>
                    </label>
                    <div class="compare-added-label compare-notification">Added</div>
                    <div class="compare-removed-label compare-notification">Removed</div>
                  </div>
                  <button class="sku" @click="copySku" :data-sku="product.data.id">
                    <span class="wrap">
                      {{ product.data.id }}
                      <Copy />
                    </span>
                    <div class="copy-added-label copy-notification">Copied</div>
                  </button>
                </div>
              </div>
            </div>
            <WishlistIcon
              :inWishlist="this.inWishlist(product.data.id)"
              :productName="product.data.brand_collection + ' ' + product.value"
              @addToWishlist="this.addToWishlist(product)"
              @removeFromWishlist="this.removeFromWishlist(product)"
            />
          </li>
        </template>
        <template v-else>
          <Content
            :content="product"
            v-if="loaded"
          />
        </template>
      </template>
    </ol>
  </div>
</template>

<script>

import Content from "@/components/Content.vue";
import Copy from "@/components/icon/Copy.vue";
import Listbox from "./utility/Listbox.vue";
import MobilePerRow from "@/components/MobilePerRow.vue";
import ProductImage from "@/components/product-card/ProductImage.vue";
import RatingStars from "@/components/product-card/RatingStars.vue";
import Swatches from "@/components/product-card/Swatches.vue";
import WishlistIcon from "./WishlistIcon.vue";

export default {
  name: "Products",
  components: {
    Content,
    Copy,
    Listbox,
    MobilePerRow,
    ProductImage,
    RatingStars,
    Swatches,
    WishlistIcon
},
  inject: ['wishlistManager'],
  emits: [
    'facetChange',
    'productClick',
    'toggleFilters',
  ],
  props: {
    displayName: {
      type: String,
      required: true
    },
    isSearch: {
      default: false,
      type: Boolean,
      required: true,
    },
    loaded: {
      default: false,
      type: Boolean
    },
    numResults: {
      type: Number,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    refinedContent: {
      type: Array,
      required: false
    },
    searchTerm: {
      type: String,
      required: false,
    },
    sort_options: {
      type: Array,
      required: true
    },
    tracking: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      loadingProducts: {},
      selectedSwatches: {},
      compare: [],
      compareT: [],
      compareF: [],
      productImages: [],
      wishlist: [],
      windowSearch: Object.fromEntries(new URLSearchParams(window.location.search).entries()),
      toggle_per_row_mobile: parseInt(localStorage.getItem("toggle_per_row_mobile"))
    };
  },
  computed: {
    productListClass:function() {
      return {
        'per-row-1': this.toggle_per_row_mobile === 1
      }
    },
    trackingAttributesForSearchBrowseContainer: function() {
      // https://docs.constructor.io/integration/tracking/#search--browse
      let attrs = {};

      if (this.isSearch) {
        attrs['data-cnstrc-search'] = '';
        attrs['data-cnstrc-num-results'] = this.numResults;
      }

      if (! this.isSearch) {
        attrs['data-cnstrc-browse'] = '';
        attrs['data-cnstrc-filter-name'] = this.tracking.filterName;
        attrs['data-cnstrc-filter-value'] = this.tracking.filterValue;
        attrs['data-cnstrc-num-results'] = this.numResults;
      }

      return attrs;
    },

  },
  methods: {
    cardLoop: function() {
      let cards = this.products.slice();

      this.refinedContent.forEach((content) => {
        if (! this.showGridContent(content.data)) {
          return;
        }

        let placement = 0;

        switch(content.data.placement) {
          case 'upper':
            placement = this.isMobile() ? (this.toggle_per_row_mobile === 1 ? 3 : 6) : 0;
            break;
          case 'middle':
            placement = this.isMobile() ? (this.toggle_per_row_mobile === 1 ? 7 : 14) : 9;
            break;
          case 'lower':
            placement = this.isMobile() ? (this.toggle_per_row_mobile === 1 ? 11 : 22) : 21;
            break;
        }

        switch(content.data.position) {
          case 'middle':
            placement += this.isMobile() ? 0 : 1;
            break;
          case 'right':
            placement += this.isMobile() ? (this.toggle_per_row_mobile === 1 ? 0 : 1) : 2;
            break;
        }

        cards.splice(placement, 0, content);
      });

      return cards;
    },
    isMobile: function() {
        return window.matchMedia('(max-width: 1023px)').matches;
    },
    productName(productName, productBrand) {
      let product_name = productName.replace(productBrand, '')
      // Special case for different naming than brand value
      product_name = product_name.replace('Infinity By Cherokee','');
      return product_name
    },
    priceRange(data) {
      if (!data) {
        return;
      }

      let range = data.split(' - ')
      if (range[0] === range[1]) {
        return range[0]
      } else {
        return data
      }
    },
    promoFlag(data) {
      if(!data?.promo_flag?.is_active) {
        return '';
      }
      let isActive = data.promo_flag.is_active,
          bgColor = data.promo_flag.background_color,
          color = data.promo_flag.text_color,
          text = data.promo_flag.text;

      return `<div data-active="${isActive}" class="promo-flag" style="background-color:${bgColor};color:${color};">${text}</div>`
    },
    getPrimaryImage: function(product) {
      if (this.productImages[product.data.id]) {
        return this.productImages[product.data.id].image;
      }

      return product.data.image_url;
    },
    getSecondaryImage: function(product) {
      if (this.productImages[product.data.id]) {
        return this.productImages[product.data.id].alter_image;
      }

      return product.data.alter_image_url;
    },
    copySku: function(e) {
      let copyText = e.target.closest(".sku").getAttribute("data-sku");
      e.target.closest('.sku').classList.add('copy-active', 'interacted')
      setTimeout(function() {
        e.target.closest('.sku').classList.remove('copy-active');
      }, 4000);
      if (navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(copyText);
        return;
      }

      if (window.clipboardData) {
        window.clipboardData.setData("Text", copyText);
      }
    },
    toggleCompare: function(product) {
      if (this.compare.includes(product.data.id)) {
        this.removeFromCompare(product);

        let tindex = this.compareT.indexOf(product.data.id);
        if (tindex !== -1) {
          this.compareT.splice(tindex, 1);
        }
        this.compareF.push(product.data.id);
      } else {
        this.addToCompare(product);

        let findex = this.compareF.indexOf(product.data.id);
        if (findex !== -1) {
          this.compareF.splice(findex, 1);
        }
        this.compareT.push(product.data.id);
      }
    },
    toggleChangeHandlerMobile: function(perRow) {
      this.toggle_per_row_mobile = perRow;
    },
    inWishlist: function(sku) {
      return this.wishlist.includes(sku);
    },
    getSalePriceColor() {
      return window.search.salePriceColor || '#E9014B';
    },
    getCategoryTitle() {
      return decodeURIComponent(JSON.parse('"' + window.search.categoryTitle + '"')) || '';
    },
    addToWishlist(product) {
        if (import.meta.env.MODE !== 'production') {
            this.wishlist.push(product.data.id);
            return;
        }

        let loggedIn = this.wishlistManager.loggedIn();

        if (loggedIn) {
            let url = window.location.origin + '/rest/default/V1/wishlist/add/' + product.data.id;

            this.callApi(url, 'POST')
                .then(response => {
                    if (response === true) {
                        this.wishlist.push(product.data.id);
                    }
                }).then(() => {
                    let customEvent = new CustomEvent('fetchComplete', {detail: {url: url}});
                    document.dispatchEvent(customEvent);
                }).catch(error => {
                    console.log(error);
                });
        }

        if (!loggedIn) {
            this.wishlist.push(product.data.id);
        }
    },
    removeFromWishlist(product) {
      let index = this.wishlist.indexOf(product.data.id);

      if (index === -1) {
        return;
      }

      if (import.meta.env.MODE !== 'production') {
        this.wishlist.splice(index, 1);
        return;
      }

      let loggedIn = this.wishlistManager.loggedIn(),
          url = window.location.origin + '/rest/default/V1/wishlist/remove/' + product.data.id;

      if (loggedIn) {
          this.callApi(url, 'DELETE')
              .then(response => {
                  if (response === true) {
                      this.wishlist.splice(index, 1);
                  }
              }).then(() => {
                  let customEvent = new CustomEvent('fetchComplete', {detail: {url: url}});
                  document.dispatchEvent(customEvent);
              }).catch(error => {
                  console.log(error);
              });
      }

      if (!loggedIn) {
          this.wishlist.splice(index, 1);
      }
    },
    async callApi(url, method, body = {}) {
      let data = {
        method: method,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
      };

      if (!this.isObjectEmpty(body)) {
        data.body = body;
      }

      const response = await fetch(url, data);

      return response.json();
    },
    isObjectEmpty(obj) {
      return obj
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype;
    },
    getFormKey() {
      return document.cookie
        .split('; ')
        .find(row => row.startsWith('form_key='))
        .split('=')[1] || '';
    },
    addToCompare(product) {
      if (import.meta.env.MODE !== 'production') {
          this.compare.push(product.data.id);
          return;
      }

      let url = window.location.origin + '/compare/add/';

      let data = {
        'product-id': product.data.id,
        uenc: btoa(product.data.url).replace('+/=', '-_,'),
        form_key: this.getFormKey(),
      }

      let formData = new FormData();
      for (const p in data) {
        formData.append(p, data[p]);
      }

      this.callApi(url, 'POST', formData)
      .then(response => {
        if (response === true) {
          this.compare.push(product.data.id);
        }
      }).then(() => {
        let customEvent = new CustomEvent('fetchComplete', {detail: {url: url}});
        document.dispatchEvent(customEvent);
      }).catch(error => {
        console.log(error);
      });
    },
    removeFromCompare(product) {
      let index = this.compare.indexOf(product.data.id);

      if (index === -1) {
        return;
      }

      if (import.meta.env.MODE !== 'production') {
        this.compare.splice(index, 1);
        return;
      }

      let url = window.location.origin + '/compare/remove/';

      let data = {
        'product-id': product.data.id,
        uenc: btoa(product.data.url).replace('+/=', '-_,'),
        form_key: this.getFormKey(),
      }

      let formData = new FormData();
      for (const p in data) {
        formData.append(p, data[p]);
      }

      this.callApi(url, 'POST', formData)
      .then(response => {
        if (response === true) {
            this.compare.splice(index, 1);
        }
      }).then(() => {
        let customEvent = new CustomEvent('fetchComplete', {detail: {url: url}});
        document.dispatchEvent(customEvent);
      }).catch(error => {
        console.log(error);
      });
    },
    clearAll() {
      let url = window.location.origin + '/compare/clear/';

      let data = {
        form_key: this.getFormKey(),
      }

      let formData = new FormData();
      for (const p in data) {
        formData.append(p, data[p]);
      }

      this.callApi(url, 'POST', formData)
        .then(response => {
          if (response === true) {
            this.compare = [];
          }
        }).then(() => {
          let customEvent = new CustomEvent('fetchComplete', {detail: {url: url}});
          document.dispatchEvent(customEvent);
        }).catch(error => console.log(error));
    },
    cprBanner(product) {
      if(!product.catalog_price_rules?.length) {
        return '';
      }
      return `<div class="cpr-banner" data-product="${product.id}" style="background-color:${product.catalog_price_rules[0]['color_choice']}"><span style="color:${product.catalog_price_rules[0].text_color_choice}">${product.catalog_price_rules[0].plp_banner_text}</span></div>`
    },
    setSwatchCookie(product) {
      let selectedSwatch = {};

      if (this.selectedSwatches[product.data.id] === undefined) {
        let swatchOptions = product.data?.swatch_options?.[206];
        if (!swatchOptions) {
          return;
        }

        let swatchData = Object.values(swatchOptions)
          .filter((so) => so.label === product.data.color)
          .pop();

        // The products in the API response will sometimes be the parent product
        // and sometimes be the child products.  Parent products don't always
        // have "Color" set; so product.data.color will not be present on those.
        // When that happens the filter returns nothing.  This check is here to
        // prevent an error when trying to set the swatchData, M2PL-5344:
        // Cannot read properties of undefined (reading 'option_id')
        if (swatchData) {
          selectedSwatch[product.data.id] = swatchData.option_id;
        }

      } else {
        selectedSwatch[product.data.id] = this.selectedSwatches[product.data.id];
      }

      document.cookie = 'selected_swatch=' + encodeURIComponent(JSON.stringify(selectedSwatch)) + ";path=/";
    },
    isSearchPage() {
      return window.location.pathname.includes('/catalogsearch/result/');
    },
    showGridContent(data) {
      if (data.placement === 'top' ) {
        return false;
      }

      let currentPage = this.$root.selectedFacets.getPage();
      return !(data.pages !== 'all' &&
          parseInt(data.pages, 10) !== parseInt(currentPage, 10));
    },
    trackingAttributesForSearchBrowseResult: function(product) {
      let attrs = {};

      attrs['data-cnstrc-item-id'] = product.data.id;
      attrs['data-cnstrc-item-name'] = product.value;

      return attrs;
    },
    formatPrice: function (price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return formatter.format(price);
    }
  },
  mounted: function() {
    let self = this,
        compareCache = localStorage.compare ? JSON.parse(localStorage.compare) : null,
        loggedIn = this.wishlistManager.loggedIn(),
        mageCache = JSON.parse(localStorage.getItem('mage-cache-storage')),
        mageCompare = mageCache?.['compare-products']?.['items'] ?? [],
        mageWishlist = mageCache?.['wishlist']?.['items'] ?? [],
        wishlistCache = localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [];

    if (compareCache === null || (compareCache.length !== mageCompare.length)) {
      this.callApi(
        '/compare/',
        'GET'
      ).then(json => {
        if (Array.isArray(json)) {
          self.compare = json;
        }
      }).catch(error => console.log(error));
    } else {
      this.compare = compareCache;
    }

    if(loggedIn) {
        if (wishlistCache.length === 0 || (wishlistCache.length !== mageWishlist.length)) {
            this.callApi(
                '/rest/default/V1/wishlist/',
                'GET'
            ).then(json => {
                if (Array.isArray(json)) {
                    self.wishlist = json;
                }
            }).catch(error => console.log(error));
        } else {
            this.wishlist = wishlistCache;
        }
    }

    if (!loggedIn) {
        this.wishlist = wishlistCache;
    }
  },
  watch: {
    toggle_per_row_mobile: {
      handler (newToggle) {
        localStorage.setItem("toggle_per_row_mobile", newToggle);
      }
    },
    compare: {
      handler (newCompare) {
        localStorage.compare = JSON.stringify(newCompare);
      },
      deep: true,
    },
    wishlist: {
      handler (newWishlist) {
        localStorage.wishlist = JSON.stringify(newWishlist);
      },
      deep: true,
    },
  }
}
</script>

<style>
:root {
  --color-compare: #16960B;
  --color-gray40: #666;
}
</style>
<style scoped>
wrap {
  display: flex;
}
.search-description {
  color: var(--color-black);
  font-size: calc(var(--font-size-large) + 4px);
  line-height: 24px;
  /* This margin is comping for other current styles and margins not collapsing for some reason */
  margin: 20px 0 5px;
}
.search-description .num-results {
  color: var(--color-dark-gray);
  font-weight: var(--font-weight-bold);
}
.search-description .search-term {
  font-weight: var(--font-weight-bold);
}
.products-grid.loaded .skeleton,
.products-grid.loaded .loaded,
.products-grid:not(.loaded) .filter.mobile,
.products-grid:not(.loaded) .products-per-row.mobile,
.products-grid:not(.loaded) .total-items,
.products-grid:not(.loaded) #sort_by {
  display: none;
}

.products-per-row.mobile {
  display: inline-flex;
  margin-bottom: 15px;
  margin-top: 25px;
  justify-content: space-between;
  width: 100%;
}
.flex-container {
  display: flex;
}

.product-items {
  font-size: unset; /* Fixes bug from styles bleeding in from Magento */
  list-style: none;
  margin: 0;
  padding-bottom: 30px;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 15px;
  margin-bottom: 65px;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .product-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

ol.product-items > li {
  margin-bottom: 65px;
}

@media only screen and (min-width: 1024px) {
  ol.product-items > li {
    margin-bottom: 35px;
  }
}

.products-grid ol.products.list.items.product-items li .product-item-info {
  padding: 0;
  position: relative;
  width: auto;
  z-index: unset; /* Undoing core style from listings.less */
}
.products-grid ol.products.list.items.product-items li .product-item-info:not(.loading) .loader {
  display:none;
}
.products-grid ol.products.list.items.product-items li .product-item-info.loading .loader,
.products-grid ol.products.list.items.product-items li .product-item-info.loading .loader:after {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.products-grid ol.products.list.items.product-items li .product-item-info.loading .loader {
  margin: 0px auto;
  font-size: 5px;
  text-indent: -9999em;
  border-top: 4px solid rgba(185, 185, 185, 0.6);
  border-right: 4px solid rgba(185, 185, 185, 0.6);
  border-bottom: 4px solid rgba(185, 185, 185, 0.6);
  border-left: 4px solid var(--brand-primary);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 18vh;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.products-grid ol.products.list.items.product-items li .product-item-info:hover {
  border: none !important;
}
.products-grid ol.products.list.items.product-items > li:nth-child(2n) {
  padding-right: 0;
}
/* Desktop Only */
@media only screen and (min-width: 1024px) {
  .products-per-row.mobile {
    display: none;
  }
  .products.products-grid {
    width: calc(78% - 60px);
    display: block;
    margin: 0;
    float: right;
  }
}
@media screen and (max-width: 767px) {
  .product.product-item-details :deep(.cpr-banner) {
    white-space: wrap !important;
    line-height: 14px;
  }
}
.product-item-link {
  text-decoration: none;
  color: #282828;
  line-height: 1.25;
}
.item-collection {
  display: block;
  margin-bottom: 4px;
}
.item-name {
  display: block;
  font-size: 13px;
}
.product {
  position: relative;
}
.products-grid ol.products.list.items.product-items li :deep(.promo-flag) {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #fafafa;
    border-radius: 2px;
    color: var(--color-black);
    font-size: 12px;
    font-weight: 900;
    height: 18px;
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .products-grid ol.products.list.items.product-items li :deep(.promo-flag) {
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .products-grid ol.products.list.items.product-items.per-row-1 li :deep(.promo-flag),
  .products-grid ol.products.list.items.product-items:not(.per-row-1) li:nth-child(odd) :deep(.promo-flag) {
    left: -7px; /* Images are -15px, this should get an 8px gap */
  }
}
.products-grid ol.products.list.items.product-items li .product.product-item-details {
  text-align: left;
  padding: 10px 0 0;
}
.products-grid ol.products.list.items.product-items li .product.product-item-details .product.name.product-item-name a.product-item-link {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  overflow-wrap: anywhere;
}
.products-grid ol.products.list.items.product-items li .product.product-item-details .price {
  margin: 16px 0 4px;
}
.products-grid ol.products.list.items.product-items li .product.product-item-details .sale-price {
  color: var(--color-sale);
  font-weight: var(--font-weight-bold);
  margin-bottom: 4px
}
.total-items {
  display: none;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  color: var(--color-dark-gray);
}
.products-per-row .total-items {
  display: flex;
}
.filter.mobile {
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-lighter-gray);
  cursor: pointer;
  display: none;
  float: right;
  font-size: var(--font-size-medium);
  height: 35px;
  justify-content: center;
  padding: 0 15px;
  background: var(--color-white);
}
.product.product-item-details .compare-sku {
  display: none;
  justify-content: space-between;
  margin-top: 25px;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 1;
}
.product.product-item-details .compare-sku a {
  position: absolute;
  top: 28px;
  left: 32px;
  color: var(--color-dark-gray);
  font-size: calc(var(--font-size-small) - 2px);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 300ms;
  z-index: 999;
  visibility: hidden;
}
.product.product-item-details .compare-sku.compare-true a {
  transition: opacity 0.3s ease 3s, visibility 0s linear 300ms;
}
.product.product-item-details .incompare.compare-sku a,
.product.product-item-details .compare-true.compare-sku a {
  opacity: 1;
  visibility: visible;
}
.product.product-item-details .compare-sku .sku .wrap {
  display: flex;
}
.product.product-item-details .compare-sku .sku .wrap:focus-visible {
  outline: var(--brand-primary) auto 1px;
}
.product.product-item-details .compare-sku .sku .wrap :deep(svg) {
  margin-left: 5px;
}
.compare-sku:not(.compare-true) {
  opacity: 0;
}
.compare-sku.incompare {
  opacity: 1;
}
.compare-sku.compare-false .compare-removed-label {
  animation: fadeinout 3s ease-in forwards;
}
.compare-sku.compare-true .compare-added-label {
  color: var(--color-compare);
  animation: fadeinout 3s ease-in forwards;
}

@keyframes fadeinout {
    0%   { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes fade-in {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes fade-out {
	0%   { opacity: 1; }
	100% { opacity: 0; }
}

.copy-notification,
.compare-notification {
  opacity: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA3IiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTA3IDI1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCAwSDk0LjVDMTAxLjQwNCAwIDEwNyA1LjU5NjQ0IDEwNyAxMi41QzEwNyAxOS40MDM2IDEwMS40MDQgMjUgOTQuNSAyNUgyMEM4Ljk1NDMxIDI1IDAgMTYuMDQ1NyAwIDVWMFoiIGZpbGw9IiNFQkVCRUIiLz4KPC9zdmc+Cg==');
  width: 107px;
  height: 25px;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #003B80;
  position: absolute;
  top: calc(100% + 4px);
}
li.item.product.product-item:focus-within .compare-sku,
li.item.product.product-item:hover .compare-sku {
  opacity: 1;
}
.compare-sku .sku {
  all: unset;
  color: var(--color-dark-gray);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.compare-sku .sku:focus-visible {
  outline: var(--brand-primary) auto 1px;
}
.copy-notification {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA3IiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTA3IDI1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTA3IDBIMTIuNDk5OEM1LjU5NTk5IDAgLTcuNjI5MzllLTA2IDUuNTk2NDQgLTcuNjI5MzllLTA2IDEyLjVDLTcuNjI5MzllLTA2IDE5LjQwMzYgNS41OTU5OSAyNSAxMi40OTk4IDI1SDg2Ljk5OThDOTguMDQ1NSAyNSAxMDcgMTYuMDQ1NyAxMDcgNVYwWiIgZmlsbD0iI0VCRUJFQiIvPgo8L3N2Zz4K');
  right: 0;
}
.compare-sku .sku.copy-active .copy-added-label.copy-notification {
  animation: fade-in 0.5s ease-in-out forwards;
}
.compare-sku .sku.interacted:not(.copy-active) .copy-added-label.copy-notification {
  animation: fade-out 0.5s ease-in-out forwards;
}
.compare-sku .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.compare-sku .container:focus-within input:not(:checked) ~ .checkmark,
.compare-sku .container:hover input:not(:checked) ~ .checkmark {
  border-color: var(--color-gray40);
}
.compare-sku .compare {
  height: 26px;
}
.compare-sku .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}
.compare-sku .checkmark {
  transition: border 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: var(--color-white);
  border: 2px solid #979797;
}
.compare-sku .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.compare-sku .container input:checked ~ .checkmark:after {
  display: block;
}
.compare-sku .container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid var(--color-compare);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.compare-sku .container .label-text {
  margin-left: 32px;
  line-height: 12px;
  height: auto;
  display: flex;
  align-items: center;
  color: var(--color-dark-gray);
  transition: color 0.3s ease;
  font-weight: var(--font-weight-bold);
}
.compare-sku .container:focus-within .label-text,
.compare-sku .container:hover .label-text {
  color: #4e4e4e;
}
.compare-sku .container .sku {
  display: flex;
  align-items: center;
}
.product.product-item-details :deep(.cpr-banner) {
  font-size: 12px;
  background-color: #FE4580;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  margin-bottom: 10px;
  /* This nowrap will also break the
  desktop product grid if the title
  becomes too long */
  white-space: nowrap;
  padding: 5px;
}

/* Loading skeleton */
.products-grid:not(.loaded) .skeleton.text.loading-name {
  height: 20px;
  display: inline-block;
  margin: 30px 0 15px;
  width: 200px;
}
.aspect-ratio-3x4 {
  padding-bottom: 140%;
  height: 0;
}
.skeleton.text {
  height: 20px;
  margin-bottom: 10px;
  width: 100%;
}
.skeleton.text.short {
  height: 20px;
  width: 50%;
}
.skeleton.toggle.text {
  margin-bottom: 15px;
  margin-top: 25px;
  height: 37px;
  max-width: 188px;
}

/* Desktop Only */
@media only screen and (min-width: 1024px) {
  .product.product-item-details .compare-sku {
    display: flex;
  }
  .product-item-details .product.product-item-details .cpr-banner,
  .product-item-details .sale-price,
  .product-item-details .price,
  .product-item-details a.product-item-link {
    line-height: 1.5;
  }
  .product-item-details .product.product-item-details .cpr-banner,
  .product-item-details a.product-item-link {
    font-size: var(--font-size-medium);
  }
  .product-item-details .sale-price,
  .product-item-details .price {
    font-size: 12px;
  }
  .total-items {
    display: inline-flex;
    height: 37px; /* Magic number to match #sort_by */
    margin-bottom: 15px; /* Magic number to match #sort_by */
    margin-top: 25px; /* Magic number to match #sort_by */
    padding-left: 0;
  }
  .product.product-item-details .cpr-banner {
      font-size: var(--font-size-medium);
  }
}
/* Tablet and down */
@media only screen and (max-width: 1023px) {
  .products.wrapper {
    order: 5;
    margin-top: 0;
  }
  .search-description {
    margin-top: 0;
  }
  #sort_by {
    display: none;
  }

  .product-items.per-row-1 {
    grid-template-columns: 1fr;
  }

  .product-items:not(.per-row-1) .promo-flag {
    height: 20px;
    padding: 0 8px;
    font-size: calc(var(--font-size-large) + 4px);
  }
  .products.wrapper.grid.products-grid {
    padding-inline: 15px;
  }
  .filter.mobile {
    display: flex;
  }
}
/* Smaller Mobile Only */
@media only screen and (max-width: 480px) {
  .products-grid .items.product-items:not(.per-row-1) :deep li.item.product .product-item-info .promo-flag[data-active="true"] {
    font-size: calc(var(--font-size-small) - 2px);
    padding: 0 7px !important;
  }
  .products.list.items.product-items:not(.per-row-1) .product .promo-flag {
    max-width: calc(100% - 50px);
  }
}
</style>
